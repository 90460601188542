import React, { useContext,useState } from 'react';
import EditableMapComponent from '../../components/Map/EditableMapComponent';
import { CityContext } from '../../Context/CityContext';
import CreateMarkerForm from '../Marker/CreateMarkerForm';
import UpdateMarkerForm from '../Marker/UpdateMarkerForm';
import ShowMarkerInfo from '../Marker/ShowMarkerInfo';

const RightBar = () => {
    const { city, currentCheckedChangeId, addMarker,setAddMarker,editMarker,setEditMarker,selectedMarker } = useContext(CityContext);
    ////console.log("Righrt editMarker",editMarker)
    if (!city) {
        return null;
    }

    return (
        <div className="w-full h-96">
            {addMarker && addMarker.status && !addMarker.selected ? (<div className="flex justify-between items-center ml-6 mb-2">
                <div>
                    <p className="text-2xl font-bold">Select Coordinates</p>
                </div>

                <div>
                    <button className="mr-8 bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md" onClick={() => {setAddMarker(null)}}>Cancel</button>
                </div>

            </div>) :!addMarker&&
                (<div>
                    <p className="city-name text-xl font-bold ml-6 mt-2">{city.name}</p>
                    <p className="sub-city-name text-xl font-bold ml-6">{city.subtitle}</p>
                </div>)}
                
                {(addMarker && addMarker.status && !addMarker.selected || !addMarker)&&(editMarker && editMarker.status && !editMarker.selected || (!editMarker || !editMarker.status))&&<EditableMapComponent city={city} currentCheckedChangeId={currentCheckedChangeId}/>}
                {!addMarker && (editMarker&&!editMarker.status) &&selectedMarker&&<ShowMarkerInfo editCityButton={true} selectedMarker={selectedMarker} editMarker={editMarker} setEditMarker={setEditMarker}/>}
                {addMarker && addMarker.status && addMarker.selected &&<CreateMarkerForm />}
                {editMarker && editMarker.status && editMarker.selected &&<UpdateMarkerForm/>}


        </div>


    );
};

export default RightBar;
