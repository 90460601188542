import React, { createContext, useState, useEffect,useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import cityService from '../services/CityService';
import SavedMapService from '../services/SavedMapService';
import { getWithExpiry } from './../utils/StoreDataWithTimeLimit'

export const CityContext = createContext();

export const CityProviderForUser = ({ children }) => {
    const [changedCheckBoxId, setChangedCheckBoxId] = useState(null);
    const { cityName } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapId = queryParams.get('mapId');
    const [loading, setLoading] = useState(true);
    const [city, setCity] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const tempCheckData=useRef()


    useEffect(() => {
        const fetchCity = async () => {
            try {
                let cityData;

                if (mapId && mapId != "temp") {
                    const [savedMapData, cityDataResponse] = await Promise.all([
                        SavedMapService.getSavedMapById(mapId),
                        cityService.getCityByName(cityName)
                    ]);

                    cityData = cityDataResponse;
                    cityData.mapId = mapId
                    cityData.mapData = savedMapData; // Assuming `checkData` is where you want to store savedMapData
                }

                else {
                    cityData = await cityService.getCityByName(cityName);
                }

                if (mapId && mapId == "temp") {
                    let checkData=getWithExpiry("checkData")
                    
                    if(checkData)
                        tempCheckData.current=checkData

                    
                    if (tempCheckData){
                        
                        cityData.mapData={}
                        cityData.mapData.checkData=tempCheckData.current
                    }
             }

                setCity(cityData);
            } catch (err) {
                console.error('Failed to fetch city data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchCity();
    }, [cityName, mapId]);

    return (
        <CityContext.Provider value={{ changedCheckBoxId, setChangedCheckBoxId, city, loading, setSelectedMarker, selectedMarker }}>
            {children}
        </CityContext.Provider>
    );
};
