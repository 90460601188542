import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as d3 from 'd3';
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';

const MapComponent = ({ city,setSelectedMarker }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const iconRatio = 34 / 1512;
    

    useEffect(() => {
        if (mapRef.current) return; // If the map is already initialized, do nothing

        // Calculate dimensions based on available width and maintain aspect ratio 1920:1080
        const calculateDimensions = () => {
            mapContainerRef.current.style.width = 'calc(100% - 50px)';
            const containerWidth = mapContainerRef.current.clientWidth;
            const maxWidth = 1920; // Maximum width
            const maxHeight = 1080; // Maximum height

            let width = containerWidth;
            let height = (width * maxHeight) / maxWidth;

            // Adjust dimensions if height exceeds maxHeight
            if (height > maxHeight) {
                height = maxHeight;
                width = (height * maxWidth) / maxHeight;
            }

            return { width, height };
        };

        // Initialize the map with calculated dimensions
        const { width, height } = calculateDimensions();
        setDimensions({ width, height });
        if (mapContainerRef.current) {
            mapContainerRef.current.style.width = `${width}px`;
            mapContainerRef.current.style.height = `${height}px`;
        }

        const map = L.map(mapContainerRef.current, {
            crs: L.CRS.Simple,
            minZoom: 0,
            maxZoom: 4,
            maxBounds: L.latLngBounds([[0, 0], [height, width]])
        }).setView([0, 0], 0);
        mapRef.current = map;

        // Add a base SVG image
        L.imageOverlay(city.baseMap.imageUrl, [[0, 0], [height, width]]).addTo(map);

        let actualMarkerPos=[]
        let allMarkers=[]

        // Define layer groups for different categories
        const addMarker = (newLayer, markerData) => {
            const markerName = `${markerData.name}`;
            const latLng = [markerData.coordinates.latitude * height, markerData.coordinates.longitude * width];
            actualMarkerPos.push({x:markerData.coordinates.latitude * height,y:markerData.coordinates.longitude * width})
            
            const marker = L.marker(latLng, { draggable: false })
                .bindPopup(`${markerName}`);

            
                const zoomScale = 1
                const iconSize = Math.ceil(Math.ceil(iconRatio*width) * zoomScale);
                marker.setIcon(new L.Icon({
                    iconUrl: returnCorrectImageUrl(markerData.marker_icon,"small"),
                    iconSize: [iconSize, iconSize],
                    iconAnchor: [iconSize / 2, iconSize],
                    popupAnchor: [0, -iconSize / 2]
                }));
            
            marker.on('click',()=>{////console.log(markerData);
                setSelectedMarker(markerData)})
            marker.addTo(newLayer);
            allMarkers.push(marker)
            
        };

        const overlays = {};
        for (let i = 0; i < city.layers.length; i++) {
            let newLayer = L.layerGroup().addTo(map);
            overlays[city.layers[i].name] = newLayer;
            for (let marker of city.layers[i].markers) {
                marker.layerId=city.layers[i]._id
                marker.cityId=city._id
                addMarker(newLayer, marker);
            }
        }
        function updateMarkerPositions() {
           
            let currentMarkerNodes = []
            actualMarkerPos.forEach(function(marker) {
                let physicalCoordinate = map.latLngToLayerPoint(L.latLng(marker.x, marker.y));
                let node = {x: physicalCoordinate.x, y: physicalCoordinate.y, r: 25 }
                currentMarkerNodes.push(node);
            });
        
            var simulation = d3.forceSimulation()
            .force("collide", d3.forceCollide().radius(function(d) {
                ////console.log(d.r)
                return d.r+1; // Add a small buffer
            }).iterations(0.1));
        
            simulation.nodes(currentMarkerNodes).tick();
            ////console.log("currentMarkerNodes",currentMarkerNodes)
        
            allMarkers.forEach(function(marker, i){
                const zoomScale = 1+(0.5*map.getZoom())
                const iconSize = Math.ceil(Math.ceil(iconRatio*width) * zoomScale);
                marker.setIcon(new L.Icon({
                    iconUrl: marker.options.icon.options.iconUrl,
                    iconSize: [iconSize, iconSize],
                    iconAnchor: [iconSize / 2, iconSize],
                    popupAnchor: [0, -iconSize / 2]
                }));
                let newCoordinate = currentMarkerNodes[i]; 
                marker.setLatLng(map.layerPointToLatLng(L.point(newCoordinate.x, newCoordinate.y)));
            });
        }
        
        map.on('zoomend', updateMarkerPositions);
        updateMarkerPositions();

        L.control.layers(overlays).addTo(map);
        Object.values(overlays).forEach(layer => map.addLayer(layer));

        // Cleanup function to remove map instance on component unmount
        return () => {
            map.remove();
            mapRef.current = null;
        };
    }, [city]); // Dependency on city to re-render map if city changes

    // Recalculate dimensions on window resize
    useEffect(() => {
        const handleResize = () => {
            if (mapRef.current) {
                const { width, height } = calculateDimensions();
                setDimensions({ width, height });
                mapContainerRef.current.style.width = `${width}px`;
                mapContainerRef.current.style.height = `${height}px`;
                mapRef.current.invalidateSize();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Calculate dimensions again for the container and set the style
    const calculateDimensions = () => {
        mapContainerRef.current.style.width = 'calc(100% - 50px)';
        const containerWidth = mapContainerRef.current.clientWidth;
        const maxWidth = city.baseMap.dimension.width; // Maximum width
        const maxHeight = city.baseMap.dimension.height; // Maximum height

        let width = containerWidth;
        let height = (width * maxHeight) / maxWidth;

        // Adjust dimensions if height exceeds maxHeight
        if (height > maxHeight) {
            height = maxHeight;
            width = (height * maxWidth) / maxHeight;
        }

        return { width, height };
    };

    return (
        <div
            id="map"
            ref={mapContainerRef}
            style={{
                maxWidth: '1920px',
                maxHeight: '1080px',
                margin: '0 auto'
            }}
        ></div>
    );
};

export default MapComponent;
