import imageUploadService from '../services/ImageUploadService';

export const initiateUpload = async (file) => {
  try {
    const response = await imageUploadService.initiateUpload(file.size/1024, file.name, file.type);
    return { fileId: response.fileId, chunkSize: response.chunkSize*1024 };
  } catch (error) {
    console.error('Error initiating upload:', error);
    throw error;
  }
};

export const uploadChunks = async (file, fileId, chunkSize,setUploadedpercentage) => {
  const totalChunks = Math.ceil(file.size / chunkSize);
  for (let i = 0; i < totalChunks; i++) {
    const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
    try {
      await imageUploadService.uploadChunk(fileId, i + 1, chunk);
    } catch (error) {
      console.error(`Error uploading chunk ${i + 1}:`, error);
      throw error;
    }
    ////console.log(i+1,totalChunks,Math.ceil(((i+1)/totalChunks)*100))
    if(setUploadedpercentage)
    setUploadedpercentage(Math.ceil(((i+1)/totalChunks)*100))
  }
};

export const completeUpload = async (fileId) => {
  try {
    const response = await imageUploadService.completeUpload(fileId);
    ////console.log('Upload complete:', response);
    return response;
  } catch (error) {
    console.error('Error completing upload:', error);
    throw error;
  }
};
