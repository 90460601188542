import UserDashBoard from "../../user view src/views/UserDashBoard/UserDashBoard";
import AdminDashBoard from "../Admin Dashboard/AdminDashBoard";
import Login from "../Auth/Login";
import Dashboard from "../Dashboard/Dashboard";
import { useLoaderData } from "react-router-dom";

const Home = () => {
    let { authenticated,data }=useLoaderData()
    return authenticated ? (data.userType==="admin"?<AdminDashBoard />:<UserDashBoard/>) : <Login />;
  };

export default Home;