import React, { useContext, useState } from 'react';
import { FaPenToSquare, FaChevronRight, FaChevronDown, FaTrash, FaPlus, FaArrowLeft } from 'react-icons/fa6';
import './EditCityLeftBar.css';
import LayerAndMarkerText from '../../components/Text/LayerAndMarkerText';
import CreateLayerModal from '../Layer/CreateLayerModal';
import SuccessModal from '../../components/Modal/SuccessModal';
import UpdateLayerModal from '../Layer/UpdateLayerModal';
import DeleteLayerModal from '../Layer/DeleteLayerModal';
import { CityContext } from '../../Context/CityContext';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import DeleteMarkerModal from '../Marker/DeleteMarkerModal';
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';

const LeftBar = () => {
    const { city, setCity, isChecked, setIsChecked, setCurrentCheckedChangeId, setAddMarker, setEditMarker, editMarker,selectedMarker,setSelectedMarker } = useContext(CityContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({ create: false, delete: false, update: false });
    const [successful, setSuccessful] = useState({ status: false, msg: 'created' });
    const [layer, setLayer] = useState('');
    const [openNodes, setOpenNodes] = useState([]);
    const navigate = useNavigate();


    if (!city) { return null }; // Handle case when city is not available yet


    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const handleLogout = () => {
        // Clear the accessToken and refreshToken from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Navigate to the root route
        navigate('/');
    };


    const handleOpenModal = (type, layer) => {
        const mapElement = document.getElementById("edit-city-right-container");
        if (mapElement) {
            mapElement.style.zIndex = -1;
        }
        if (layer) {
            setLayer(layer)
        }
        if (type === 'create') {
            setIsModalOpen({ create: true, delete: false, update: false, delete_marker: false });
        } else if (type === 'update') {
            setIsModalOpen({ create: false, delete: false, update: true, delete_marker: false });
        } else if (type === 'delete') {
            setIsModalOpen({ create: false, delete: true, update: false, delete_marker: false });
        }
        else if (type == "delete_marker") {
            setIsModalOpen({ create: false, delete: false, update: false, delete_marker: true });
        }
    };

    const handleCheckboxChange = (node_id) => {
        let data = { ...isChecked };
        data[node_id] = !data[node_id];
        setCurrentCheckedChangeId({ _id: node_id, display: !data[node_id] })
        setIsChecked(data);
    };

    const handleSuccess = (data, msg) => {
        handleCloseModal();
        let updatedCity = { ...city }
        setSuccessful({ status: true, msg: msg });
        if (msg === "Layer created") {

            updatedCity.layers.push(data);
            updatedCity.changedData = data
            updatedCity.changedData.object = "layer"
            updatedCity.changedData.action = "add"
            let checkedData = { ...isChecked };
            checkedData[data._id]=true
            setIsChecked(checkedData);

            //handleCheckboxChange(data._id)

        } else if (msg === "Layer updated") {
            const layerIndex = updatedCity.layers.findIndex(layer => layer._id === data._id);
            updatedCity.layers[layerIndex] = data;
            //handleCheckboxChange(data._id)
        } else if (msg === "Layer deleted") {
            const layerIndex = updatedCity.layers.findIndex(layer => layer._id === data);
            ////console.log(data)
            updatedCity.layers.splice(layerIndex, 1);
            updatedCity.changedData = { _id: data }
            updatedCity.changedData.object = "layer"
            updatedCity.changedData.action = "delete"
        }
        else if(msg=="Marker deleted"){
            const layerIndex = updatedCity.layers.findIndex(layerData => layerData._id === data.layerId);
            let markerIndex = updatedCity.layers[layerIndex].markers.findIndex(marker=>marker._id===data.markerId)
            updatedCity.layers[layerIndex].markers.splice(markerIndex, 1);
            updatedCity.changedData = { _id: data.markerId,layerId:layer.layerId }
            updatedCity.changedData.object = "marker"
            updatedCity.changedData.action = "delete"
            if(selectedMarker._id===data.markerId){
                setSelectedMarker(null)
            }
        }
        setCity(updatedCity); // Ensure to update state immutably
    };

    const handleCloseModal = () => {
        const mapElement = document.getElementById("edit-city-right-container");
        if (mapElement) {
            mapElement.style.zIndex = 1;
        }
        setIsModalOpen({ create: false, delete: false, update: false,delete_marker:false });
    };

    const handleMessageCloseModal = () => {
        setSuccessful({ status: false, msg: 'created' });
    };

    // Recursive component to render file tree nodes
    const TreeNode = ({ node, level = 0, parent = null }) => {
        const isOpen = openNodes.includes(node._id);
        const [isHovered, setIsHovered] = useState(false);

        const handleToggle = () => {
            if (isOpen) {
                setOpenNodes(openNodes.filter(id => id !== node._id));
            } else {
                setOpenNodes([...openNodes, node._id]);
            }
        };

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <li
                className={`ml-${level * 2} ${parent && !isChecked[parent] && "bg-gray-200 pointer-events-none opacity-50"}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="flex items-center cursor-pointer py-1 relative custom-checkbox-team">
                    {node.markers &&(
                        isOpen ? (
                            <span onClick={handleToggle}>
                                <FaChevronDown style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
                            </span>
                        ) : (
                            <span onClick={handleToggle}>
                                <FaChevronRight style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
                            </span>
                        )
                    )}

                    <input type="checkbox" className="mr-2" checked={isChecked[node._id]} disabled={false || (parent && !isChecked[parent])} onChange={() => handleCheckboxChange(node._id)} />
                    {!node.markers && <img src={returnCorrectImageUrl(node.marker_icon,"small")} alt="Base Map" className="mr-2 w-5 h-5 object-cover rounded-md border border-gray-300" />}

                    {node.markers ? (<span className={`font-semibold text-gray-800 truncate ${!parent && !isChecked[node._id] && 'pointer-events-none opacity-50'}`}>{node.name}</span>) : (
                        <LayerAndMarkerText className="font-semibold text-gray-800 truncate">{node.name}</LayerAndMarkerText>)}

                    {isHovered && (
                        <div className={`absolute right-0 flex ${!parent && !isChecked[node._id] && 'pointer-events-none opacity-50'}`}>
                            {node.markers && <FaPlus className="mr-2 text-grey-500 cursor-pointer" onClick={() => { setAddMarker({ status: true, cityId: city._id, layerId: node._id }) }} />}
                            <FaPenToSquare className="mr-2 text-blue-500 cursor-pointer" onClick={() => { parent ? setEditMarker({ ...editMarker, status: true, cityId: city._id, layerId: parent, markerId: node._id, selected: true, data: node }) : handleOpenModal('update', { name: node.name, layerId: node._id }) }} />
                            <FaTrash className="text-red-500 cursor-pointer" onClick={() => {parent ? handleOpenModal('delete_marker', { name: node.name, layerId: parent,markerId:node._id }):handleOpenModal('delete', { name: node.name, layerId: node._id }) }} />

                        </div>
                    )}
                </div>
                {isOpen && node.markers && (
                    <ul className={`ml-${level * 2} pl-2`}>
                        {node.markers.map((markerNode, index) => (
                            <TreeNode key={index} node={markerNode} level={level + 1} parent={node._id} />
                        ))}
                    </ul>
                )}
            </li>
        );
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredFileTreeData = city.layers.filter((node) =>
        node.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (

        <div className="bg-white w-full h-full p-4 border-r-2 border-gray-300 overflow-y-auto">
            <div className='go-back-button' onClick={handleGoBack}>
                <FaArrowLeft style={{ width: '15px', height: '15px', color: '#fff' }} />
                <span>List of City</span>
            </div>
            <br></br>
            <div className='layers'>
                <span>Layers</span>
                <FaPlus style={{ width: '25px', height: '25px', color: '#fff', backgroundColor: "#2B688E", cursor: "pointer", margin: '5px', borderRadius: '12.5px' }} onClick={() => { handleOpenModal('create') }} />
            </div>
            <div style={{ height: "1px", backgroundColor: '#d1d5db' }}></div>
            <ul>
                {filteredFileTreeData.map((rootNode, index) => (
                    <TreeNode key={index} node={rootNode} />
                ))}
            </ul>
            <CreateLayerModal
                cityId={city._id}
                isOpen={isModalOpen.create}
                onClose={handleCloseModal}
                onCreateSuccess={handleSuccess}
            />
            <UpdateLayerModal
                cityId={city._id}
                layer={layer}
                isOpen={isModalOpen.update}
                onClose={handleCloseModal}
                onUpdateSuccess={handleSuccess}
            />
            <DeleteLayerModal
                cityId={city._id}
                layer={layer}
                isOpen={isModalOpen.delete}
                onClose={handleCloseModal}
                onDeleteSuccess={handleSuccess}
            />
            <DeleteMarkerModal
                cityId={city._id}
                layerId={layer.layerId}
                marker={layer}
                isOpen={isModalOpen.delete_marker}
                onClose={handleCloseModal}
                onDeleteSuccess={handleSuccess}
            />
            {successful.status && (
                <SuccessModal
                    message={`${successful.msg} Successfully`}
                    onClose={handleMessageCloseModal}
                />
            )}
            <div className="absolute bottom-0 left-0 bg-white border-r border-gray-300 w-1/3.5 pt-2 pl-6 pr-6 pb-2">
                <button
                    onClick={handleLogout}
                    className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out flex items-center justify-center"
                >
                    <FaSignOutAlt className="mr-2" />
                    Logout
                </button>
            </div>
        </div>


    );
};

export default LeftBar;
