import { useContext } from "react"
import Map from "../../Components/Map/Map"
import { CityContext } from "../../Context/CityContext"
import './RightBar.css'
import MarkerInfo from "../Marker/MarkerInfo"
import SavedMapDropDown from "../UserDashBoard/SavedMap/SavedMapDropDown"

const RightBar = ({checkData}) => {
    const {city,selectedMarker}=useContext(CityContext)
    return (
        <div className="w-full">
            <div className="flex items-center">
            <div className="flex-1">
                    <p className="city-name-front text-lg font-bold mt-2 ml-2 md:ml-8 md:text-2xl">{city.name}</p>
                    <p className="sub-city-name text-xl font-bold ml-2 md:ml-8 ">{city.subtitle}</p>
                </div>
                <div className="h-full mx-2">
               
                <SavedMapDropDown label="Map Options" city={city} checkData={checkData}/>
                </div>
                </div>
                
            {Object.entries(checkData).length>0 &&<Map checkData={checkData}/>}
            {selectedMarker && <MarkerInfo selectedMarker={selectedMarker}/>}
        </div>
    )
}

export default RightBar