import React, { useState } from 'react';
import MarkerIconService from '../../services/MarkerIconService';

const DeleteMarkerIconModal = ({ markerId, isOpen, onClose, onDeleteSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleDeleteMarkerIcon = async () => {
        setLoading(true);
        try {
            await MarkerIconService.deleteMarkerIcon(markerId);
            onDeleteSuccess(); // Notify parent component of successful deletion
            setError(null);
            onClose(); // Close the modal
        } catch (error) {
            setError('Failed to delete MarkerIcon. Please try again.'); // Set error message
            console.error('Error deleting MarkerIcon:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
            <div className="bg-white p-6 rounded-lg z-10 w-96">
                <h2 className="text-lg font-semibold mb-4">Delete MarkerIcon</h2>
                <p className="mb-4">Are you sure you want to delete the selected MarkerIcon ?</p>
                {error && (
                    <div className="text-red-600 mb-4">{error}</div>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteMarkerIcon}
                        className={`bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Deleting...' : 'Delete MarkerIcon'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteMarkerIconModal;
