import { useEffect, useState } from "react";
import MarkerIconService from "../../services/MarkerIconService";
import { FaTimes } from "react-icons/fa";
import './ExistingIcons.css'
import { FaTrash } from "react-icons/fa6";
import DeleteMarkerIconModal from "./DeleteMarkerIconModal";

export default function ExistingIcon({ cityId, setIconUrl, handleCloseSelectExistingMarker }) {
    const [loading, setLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState(null);
    const [images, setImages] = useState([]);
    const [openDeleteModal,setOpenDeleteModal]=useState(false)
    const [selctedMarkerIdToDelete,setSelectedMarkerIdToDelete]=useState('')

    const handleMarkerSelection=(url)=>{
        setIconUrl(url)
        handleCloseSelectExistingMarker()
    }
    const handleOpenDeleteModal=(markerId)=>{
        setSelectedMarkerIdToDelete(markerId)
        setOpenDeleteModal(true)
    }
    const handleCloseDeleteModal=()=>{
        setOpenDeleteModal(false)
        setSelectedMarkerIdToDelete('')
        
    }
    const handleSuceesFullDelete=()=>{
        let updateImageUrls=[...imageUrls]
        updateImageUrls=updateImageUrls.filter(url=>url._id!==selctedMarkerIdToDelete)
        setImageUrls(updateImageUrls)
        handleCloseDeleteModal()
    }

    useEffect(() => {
        MarkerIconService.getMarkerIconByCityId(cityId).then((data) => {
            setImageUrls(data.data);
            setLoading(false);
        })
            .catch((err) => {
                //console.log(err);
            });
    }, [cityId]);

    useEffect(() => {
        if (!imageUrls) {
            return;
        }
        const loadImage = async (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
            });
        };

        const loadImages = async () => {
            const loadedImages = await Promise.all(imageUrls.map((url) => loadImage(url.imageUrl.small.imageUrl)));
            setImages(loadedImages);
        };

        loadImages();
    }, [imageUrls]);


    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 w-full h-full items-center justify-center flex">
            {loading ? (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
                </div>
            ) : (
                <div className="relative w-1/2 h-2/3 bg-gray-200  p-4">
                    <div className="image-grid w-full h-full flex p-4 overflow-y-scroll flex-wrap">
                        {imageUrls.map((url, index) => (
                            <div key={index} className=" w-32 h-32 p-2">
                                {!images.includes(url.imageUrl.small.imageUrl) ? (
                                    <div className="shimmer h-full w-full">
                                        <div className="shimmer-effect"></div>
                                    </div>
                                ) : (
                                    <div className="h-full w-full relative cursor-pointer" >
                                        <img
                                         onClick={()=>{handleMarkerSelection(url.imageUrl)}}
                                            src={url.imageUrl.small.imageUrl}
                                            alt={`img-${index}`}
                                            className="image object-cover h-full w-full rounded-lg"
                                        />
                                        <button
                                            className="absolute top-0 right-0 m-2 bg-red-600 text-white p-2 rounded-full"
                                            onClick={() => handleOpenDeleteModal(url._id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                       
                    </div>
                    <button
                        className="absolute -top-3 -right-3 bg-red-600 text-white p-2 rounded-full"
                        onClick={handleCloseSelectExistingMarker}
                    >
                        <FaTimes/>
                    </button>
                    {imageUrls && imageUrls.length === 0 && (
    <p className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75 text-gray-700 text-xl font-bold m-8">
        No Marker Image Found
    </p>
)}
                </div>
            )}
           <DeleteMarkerIconModal
           markerId={selctedMarkerIdToDelete}
           isOpen={openDeleteModal}
           onClose={handleCloseDeleteModal}
           onDeleteSuccess={handleSuceesFullDelete}
           />
        </div>
    );
}
