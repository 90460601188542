import { useEffect, useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import SavedMapService from "../../../services/SavedMapService";

export default function ShowSavedMap({ userId }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!userId) return;
        SavedMapService.getSavedMapsByUserId(userId)
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }, [userId]);

    return (
        <div className={`flex ${loading ? 'h-64 items-center justify-center' : 'h-auto'} w-full mb-4 mt-4`}>
            {loading && (
                <div className="y">
                <div className="animate-spin rounded-full h-16 w-16 md:h-32 md:w-32 border-t-4 border-b-4 border-blue-500"></div>
                </div>
            )}
            {error && (
                <div className="flex flex-col items-center text-red-500">
                    <FaExclamationCircle className="h-16 w-16 mb-4 mt-4 md:mb-8 md:mt-8" />
                    <p>{error}</p>
                </div>
            )}
            {data && data.length === 0 && !loading && (
                <div className="w-full flex flex-col items-center justify-center py-12">
                    <img src="https://riitii.s3.ap-south-1.amazonaws.com/1720597796232.svg" className="h-32 w-32 object-cover"/>
                    <p className="font-semibold pt-2">No maps saved yet</p>
                </div>
            )}
            {data && data.length > 0 && (
                <div className="w-full flex flex-wrap">
                    {data.map((map) => (
                        <div key={map._id} className="p-4 w-full md:w-[30%] bg-white mb-2 md:m-2 rounded-3xl" >
                            <h3 className="text-xl">{map.name}</h3>
                            <p className="text-lg text-[#718EBF]">{map.baseMapName}</p> 
                            <div className="flex justify-center mt-2">
                            <span className="text-center m-2 text-[#718EBF] px-4 py-1 border-[#718EBF] border rounded-3xl cursor-pointer" onClick={()=>{
                            window.location.href=`/${map.baseMapName}?mapId=${map._id}`
                        }}>View Map</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
