import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import SavedMapModal from './SavedMapModal';
import DeleteMapModal from './DeleteMapModal';
import SavedMapService from '../../../services/SavedMapService';
import { useNavigate } from 'react-router-dom';
import SuccessModal from './SuccessModal';
import { getWithExpiry, setWithExpiry } from '../../../utils/StoreDataWithTimeLimit';

const SavedMapDropDown = ({ label, city, checkData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mapName, setMapName] = useState(city?.mapData?.name || '');
  const [mapId, setMapId] = useState(city?.mapId || '');
  const [currentMapName, setCurrentMapName] = useState('');
  const [alreadySaved, setAlreadySaved] = useState(mapName ? true : false);
  const [saveLoading,setSaveLoading]=useState(false)
  const [deleteLoading,setDeleteLoading]=useState(false)
  const [error, setError] = useState(null);
  const [successModalOpen,setSuccessModalOpen]=useState(false)
  const [successModalMessage,setSuccessModalMessage]=useState('')
  const navigate = useNavigate();

  const updateUrl = (newPath) => {
    navigate(newPath, { replace: true });
  };

  const HandleSuccessModalOpen=(msg)=>{
    setSuccessModalMessage(msg)
    setSuccessModalOpen(true)
  }

  const HandleSuccessModalClose=()=>{
    
    setSuccessModalOpen(false)
    setSuccessModalMessage('')
    
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSaveMap = () => {
    setIsModalOpen(true);
    setAlreadySaved(mapName ? true : false);
    setIsOpen(false);
  };

  const handleDeleteMap = () => {
    setIsDeleteModalOpen(true);
    setIsOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
    setCurrentMapName('');
    
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setError(null);
  };

  const handleMapNameChange = (e) => {
    setCurrentMapName(e.target.value);
  };

  const handleSaveAsNew = async () => {
    try {
      if(!currentMapName){
        setError("Map name is required")
        return
      }
      setSaveLoading(true)
      setWithExpiry('redirectUrl',`/${city.name}?mapId=temp`,900000)
      setWithExpiry('checkData',checkData,900000)
      let savedMapData = await SavedMapService.createSavedMap({ name: currentMapName, baseMapName: city.name, checkData });
      getWithExpiry('redirectUrl')
      getWithExpiry('checkData')
      setMapName(savedMapData.name);
      setMapId(savedMapData._id);
      updateUrl(`/${savedMapData.baseMapName}?mapId=${savedMapData._id}`);
      setSaveLoading(false)
      closeModal();
      HandleSuccessModalOpen("Map copy is successfully Saved")
    } catch (error) {
      console.log(error)
      setError('Failed to save the map. Please try again.');
      setSaveLoading(false)
    }
  };

  const handleUpdateMap = async () => {
    try {
      setWithExpiry('redirectUrl',`/${city.name}?mapId${mapId}`,900000)
      setWithExpiry('checkData',checkData,900000)
      await SavedMapService.updateSavedMapById(mapId, { checkData });
      getWithExpiry('redirectUrl')
      getWithExpiry('checkData')
      closeModal();
      HandleSuccessModalOpen("Map copy is successfully updated")
    } catch (error) {
      setError('Failed to update the map. Please try again.');
    }
  };

  const handleConfirmDeleteMap = async () => {
    try {
      setDeleteLoading(true)
      
      setWithExpiry('redirectUrl',`/${city.name}?mapId${mapId}`,900000)
      await SavedMapService.deleteSavedMapById(mapId);
      getWithExpiry('redirectUrl')
      HandleSuccessModalOpen("Map copy is deleted successfully")
      
      setMapName(null);
      setMapId('');
      setAlreadySaved(false);
      setDeleteLoading(false)
      updateUrl(`/${city.name}`);
      closeDeleteModal();
    } catch (error) {
      
      setError('Failed to delete the map. Please try again.');
      setDeleteLoading(false)
    }
  };

  return (
    <div className="relative inline-block text-left">
      <div className='flex rounded-sm border border-gray-300 items-center' onClick={toggleDropdown}>
        <button
          type="button"
          className="border-r border-gray-300 inline-flex justify-between w-full px-2 py-1 bg-white text-sm text-gray-700 hover:bg-gray-50"
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {selectedOption ? selectedOption.label : label}
        </button>
        <FaChevronDown size={12} className="text-gray-800 mr-2 ml-2" />
      </div>

      {isOpen && (
        <div
          className="z-10 origin-top-right absolute right-0 w-48 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div role="none">
            <button
              onClick={handleSaveMap}
              className="border border-gray-800 text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
              role="menuitem"
            >
              Save a map copy
            </button>
            <button
              onClick={handleDeleteMap}
              className={`border border-gray-800 text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${!city.mapId && 'opacity-50'}`}
              role="menuitem"
              disabled={!alreadySaved}
            >
              Delete a map copy
            </button>
          </div>
        </div>
      )}

      <SavedMapModal
        isOpen={isModalOpen}
        onClose={closeModal}
        mapName={mapName}
        onMapNameChange={handleMapNameChange}
        onSave={handleSaveAsNew}
        onUpdate={handleUpdateMap}
        error={error}
        currentMapName={currentMapName}
        setMapName={setMapName}
        alreadySaved={alreadySaved}
        setAlreadySaved={setAlreadySaved}
        loading={saveLoading}
        
      />

      <DeleteMapModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onDelete={handleConfirmDeleteMap}
        error={error}
        name={mapName}
        loading={deleteLoading}
        
      />

      <SuccessModal
        isOpen={successModalOpen}
        message={successModalMessage}
        onClose={HandleSuccessModalClose}
      />
    </div>
  );
};

export default SavedMapDropDown;
