import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaXmark } from 'react-icons/fa6';
import ImageOrVideoOrFileInput from '../../components/ImageOrVideoOrFileInput'; // Adjust the path as necessary
import { CityContext } from '../../Context/CityContext';
import { updateMarker } from '../../services/MarkerService';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from '../../components/Modal/SuccessModal';
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';
import ExistingIcon from '../../components/MarkerIcon/ExistingIcons';
import { formatDefaultLocale } from 'd3';

const UpdateMarkerForm = () => {
  const { editMarker, setEditMarker, city, setCity, setSelectedMarker } = useContext(CityContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectExistingMarker, setSelectExistingMarker] = useState(false)

  const handleOpenSelectExistingMarker = () => {
    setSelectExistingMarker(true)
  }

  const handleCloseSelectExistingMarker = () => {
    setSelectExistingMarker(false)
  }

  const handleSuccessClose = () => {
    setEditMarker(null);
  };
  ////console.log(editMarker)
  const markerData = city.layers.find(layer => layer._id === editMarker.layerId).markers.find(marker => marker._id === editMarker.markerId);
  const [imageError, setImageError] = useState(editMarker.error ? editMarker.error : {
    marker_icon: { error: false, selected: false, required: true, uploaded: true },
    thumbnail: { error: false, selected: false, required: markerData.thumbnail ? true : false, uploaded: markerData.thumbnail ? true : false },
  });

  const initialFormData = markerData ? markerData : {
    name: '',
    direction_url: '',
    marker_icon: '',
    coordinates: {
      longitude: '',
      latitude: '',
    },
    caption: '',
    thumbnail: {
      fileId: '',
      imageUrl: '',
    },
  };

  const [formData, setFormData] = useState(editMarker.form ? editMarker.form : initialFormData);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = city.baseMap.imageUrl;

    image.onload = () => {
      const aspectRatio = image.width / image.height;
      let canvasWidth = canvas.width;
      let canvasHeight = canvasWidth / aspectRatio;

      if (canvasHeight > canvas.height) {
        canvasHeight = canvas.height;
        canvasWidth = canvasHeight * aspectRatio;
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

      drawPoint(context, canvasWidth * (editMarker ? editMarker.data.coordinates.longitude : 0), canvasHeight - canvasHeight * (editMarker ? editMarker.data.coordinates.latitude : 0));
    };
  }, [editMarker]);

  const drawPoint = (context, x, y) => {
    const pointSize = 5;

    context.shadowColor = 'red';
    context.shadowBlur = 10;
    context.shadowOffsetX = 3;

    context.fillStyle = "#FF0000";
    context.beginPath();
    context.arc(x, y, pointSize, 0, Math.PI * 2, true);
    context.fill();

    context.shadowColor = 'transparent';
    context.shadowBlur = 0;
    context.shadowOffsetX = 0;
  };

  const chooseCoordinateAgain = () => {
    let data = { ...editMarker };
    data.selected = false;
    data.form = formData;
    data.error = imageError;
    setEditMarker(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCoordinatesChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      coordinates: {
        ...formData.coordinates,
        [name]: value,
      },
    });
  };

  const handleThumbnailChange = (imageData, width, height) => {
    let data = { ...imageError };
    if (!imageData) {
      data.thumbnail.selected = false;
      data.thumbnail.uploaded = false;
    } else if (imageData.selected) {
      data.thumbnail.selected = true;
    } else {
      data.thumbnail.uploaded = true;
    }

    setImageError(data);
    setFormData({
      ...formData,
      thumbnail: imageData,
    });
  };

  const handleMarkerIconChange = (imageData, width, height) => {
    let data = { ...imageError };
    if (!imageData) {
      data.marker_icon.selected = false;
      data.marker_icon.uploaded = false;
    } else if (imageData.selected) {
      data.marker_icon.selected = true;
    } else {
      data.marker_icon.uploaded = true;
    }

    setImageError(data);
    setFormData({
      ...formData,
      marker_icon: imageData,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let data = { ...imageError };
    let found = false;
    if (!data.marker_icon.uploaded) {
      data.marker_icon.error = true;
      found = true;
    }

    if (data.thumbnail.selected && !data.thumbnail.uploaded) {
      data.thumbnail.error = true;
      found = true;
    }
    if (found) {
      toast.error("Please upload all Required Images");
      setImageError(data);
      setLoading(false);
      return;
    }
    try {
      ////console.log(formData);
      let data = { ...formData };
      data.coordinates.latitude = editMarker.data.coordinates.latitude;
      data.coordinates.longitude = editMarker.data.coordinates.longitude;
      const response = await updateMarker(editMarker.cityId, editMarker.layerId, editMarker.markerId, data);

      const updatedCity = { ...city };
      updatedCity.layers.forEach((layer) => {
        if (layer._id === editMarker.layerId) {
          for (let i = 0; i < layer.markers.length; i++) {
            if (layer.markers[i]._id === editMarker.markerId) {
              layer.markers[i] = response;
            }
          }
        }
      });
      response.object = "marker";
      response.action = "update";
      response.layerId = editMarker.layerId;

      //temp fix to cope with the compress image
      response.marker_icon=formData.marker_icon
      if(formData.thumbnail && (formData.thumbnail.imageUrl || formData.thumbnail.small)){
        response.thumbnail=formData.thumbnail
      }
       //temp fix to cope with the compress image

      updatedCity.changedData = response;
      setSelectedMarker({ ...data, cityId: editMarker.cityId, layerId: editMarker.layerId })
      setCity(updatedCity);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Some error occurred, Please Try Again");
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setEditMarker(null);
  };

  let isMarker={
    cityId:city._id
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className={`w-4/5 p-4 shadow-lg rounded-md bg-white ${loading && 'pointer-events-none opacity-50'}`}>
        <ToastContainer />
        <h2 className="text-2xl font-bold mb-4">Update Marker</h2>

        <div className="flex flex-row mb-4 w-full">
          <div className="w-3/5">
            <canvas ref={canvasRef} width="350" height="auto" />
          </div>
          <div className="w-2/5 pl-4">
            <label className="block text-sm font-medium text-gray-700">
              Coordinates
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="longitude"
              value={(editMarker.data.coordinates.longitude * editMarker.width).toFixed(2)}
              onChange={handleCoordinatesChange}
              placeholder="Longitude"
              className="mt-1 p-2 block w-full border rounded-md shadow-sm"
              disabled
              required
            />
            <input
              type="text"
              name="latitude"
              value={(editMarker.data.coordinates.latitude * editMarker.height).toFixed(2)}
              onChange={handleCoordinatesChange}
              placeholder="Latitude"
              className="mt-1 p-2 block w-full border rounded-md shadow-sm"
              required
              disabled
            />
            <button
              type="button"
              className="mt-4 px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              onClick={chooseCoordinateAgain}
            >
              Change Coordinates
            </button>
          </div>
        </div>

        <div className="flex flex-row mb-4">
          <div className="w-1/4 mr-4">
            <label className="block text-sm font-medium text-gray-700">
              Thumbnail Image
            </label>
            <ImageOrVideoOrFileInput onChange={handleThumbnailChange} imageUrl={formData.thumbnail && returnCorrectImageUrl(formData.thumbnail, "large")} width="auto" error={imageError.thumbnail.error} uploaded={imageError.thumbnail.uploaded} />
          </div>
          <div className="w-1/4 mr-4 text-center">
            <label className="block text-sm font-medium text-gray-700">
              Marker Image
              <span className="text-red-500">*</span>
            </label>
            <ImageOrVideoOrFileInput onChange={handleMarkerIconChange} imageUrl={formData.marker_icon && returnCorrectImageUrl(formData.marker_icon, "large")} width="auto" error={imageError.marker_icon.error} uploaded={imageError.marker_icon.uploaded} isMarker={isMarker} required />
            <button
            type="button"
            className="w-full mt-2 px-2 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 text-center"
            onClick={handleOpenSelectExistingMarker}
          >
            Existing Icons
          </button>
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Name
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="mt-1 p-2 block w-full border rounded-md shadow-sm"
              required
            />

            <label className="block text-sm font-medium text-gray-700">
              Direction URL
            </label>
            <input
              type="text"
              name="direction_url"
              value={formData.direction_url}
              onChange={handleChange}
              placeholder="Direction URL"
              className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            />
          </div>
        </div>


        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Caption
          </label>
          <textarea
            type="text"
            name="caption"
            value={formData.caption}
            onChange={handleChange}
            placeholder="Caption"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            rows="3"
          />
        </div>



        <div className="flex justify-end">
          <button
            type="button"
            className="mr-2 px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Update Marker
          </button>
        </div>

        {success && <SuccessModal message={`Marker ${formData.name} Updated Successfully`} onClose={handleSuccessClose} />}
      </form>
      {selectExistingMarker && <ExistingIcon cityId={city._id} handleCloseSelectExistingMarker={handleCloseSelectExistingMarker} setIconUrl={handleMarkerIconChange} />}
      {loading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
    </div>
  );
};

export default UpdateMarkerForm;
