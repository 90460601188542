import { useState } from "react";
import { FaAt, FaPlus } from "react-icons/fa6";
import userService from "../../services/UserService";
import CopyTextButton from "./CopyTextButton";

export default function AddInfluencer({ isOpen }) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [password, setPassword] = useState('')
    if (!isOpen) return null;

    const handleAddMore = ()=>{
        setPassword("")
        setError("")
        setSuccess(false)
        setLoading(false)
        setEmail("")
    }

    const handleAddInfluencer = async () => {
        setLoading(true)
        setError("")
        if (!email || email === "") {
            setError("Email is Required")
            setLoading(false)
            return
        }

        try {
            let response = await userService.addInfluencer({ email })
            setLoading(false)
            setSuccess(true)
            setPassword(response.password)
        } catch (error) {
            if (error?.response?.status === 409) {
                setError("Email already exists");
                setLoading(false)
                return
            }

            setError(error?.response?.data?.error ? error?.response?.data?.error[0] : "Unxepected Error occoured")
            setLoading(false)
        }
    }
    return (
        <div className="m-2 md:m-4 bg-white p-4 rounded-lg">
            <div className="rounded-md shadow-sm space-y-4 md:w-[420px]">

                <span>Email :</span>
                <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md'>
                    <FaAt size={17} className='mr-2 opacity-50' style={{ color: '#0B4654' }} />
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input
                        id="email"
                        name="email"
                        disabled={loading || success}
                        type="text"
                        required
                        className="flex-1 appearance-none focus:outline-none sm:text-sm"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 md:px-4 md:py-2 rounded relative mb-1" role="alert">
                        <strong className="font-bold">Error! <span > {error}</span></strong>

                    </div>
                )}
                <button
                    type="button"
                    onClick={handleAddInfluencer}
                    disabled={loading || success}
                    className={`bg-[#2B688E] ${(loading || success) && 'bg-gray-200'} py-2 px-4 font-semibold font-base text-white rounded-lg mt-2 min-w-[110px]`}
                >

                    {loading ? 'Adding...' : 'Add Influencer'}
                </button>
            </div>{
                success &&
            <div className="rounded-md shadow-sm md:w-[420px] mt-2">
                <span className="font-bold">Influencer is Added Successfully and credentials are sent to the email</span><br/><br/>
                password : <span className="font-bold md:inline-flex">{password} <CopyTextButton textToCopy={password} /></span><br/>
                <div className="flex items-center text-green-600 mt-2 cursor-pointer" onClick={handleAddMore}>
                    <FaPlus size={20} className="mr-2"/> Add More
                </div>
            </div>}
        </div>
    )

}