import React from 'react';
import Router from './routes';
import './App.css'
import {RouterProvider} from "react-router-dom";

const App = () => {
  return (
    <div>
      <RouterProvider router={Router} />
    </div>
  );
};

export default App;