import { useRef, useEffect, useContext,useState } from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as d3 from 'd3';
import returnCorrectImageUrl from "../../utils/returnCorrectImageUrl";
import { CityContext } from "../../Context/CityContext";

export default function Map({checkData}) {
    
    const { city, setSelectedMarker, changedCheckBoxId, setChangedCheckBoxId } = useContext(CityContext)
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    var iconRatio = 54 / 1512;
    var radius =25
    var sizeGradient=0.5
    const currentMapLayerElement = useRef({}).current;
    const actualMarkerPos = useRef([]).current;
    const allMarkers = useRef([]).current;
    const visibleMarkers=useRef({current:[]}).current;
    
    currentMapLayerElement["index"]=0

    const calculateDimensions = (doNotUpdate) => {
        iconRatio=54 / 1512;
        radius=25
        sizeGradient=0.5
        const computedStyle = window.getComputedStyle(mapContainerRef.current);
        let margin = computedStyle.marginLeft;
        margin = parseInt(margin.replace("px", "")) * 2; // Convert to integer and multiply by 2

        let deviceWidth = window.screen.width;
        let deviceHeight = window.screen.height;
        
        if(!doNotUpdate){
        mapContainerRef.current.style.width = `calc(100% - ${margin}px)`;
        mapContainerRef.current.style.height = `calc(100% - ${margin}px)`;
        }
        const containerWidth = mapContainerRef.current.clientWidth;


        const maxWidth = 1920; // Maximum width
        const maxHeight = 1080; // Maximum height
        let width = containerWidth;
        let height = (width * maxHeight) / maxWidth;

        if (height > maxHeight) {
            height = maxHeight;
            width = (height * maxWidth) / maxHeight;
        }

        if (deviceWidth > deviceHeight) {
            return { width, height };
        } else {
            iconRatio=44 / 1512;
            radius=20
            sizeGradient=0.35
            height = 0.58 * deviceHeight;
            width = (height * maxWidth) / maxHeight;
            return { width, height, containerWidth };
        }
    };

    const addMarkerData = (newLayer, markerData, width, height, map, layerId,reInitializeMap) => {
        
        const markerName = `${markerData.name}`;
        const latLng = [markerData.coordinates.latitude * height, markerData.coordinates.longitude * width];
        actualMarkerPos.push({ x: markerData.coordinates.latitude * height, y: markerData.coordinates.longitude * width })

        const marker = L.marker(latLng, { draggable: false })
            .bindPopup(`${markerName}`);

        const zoomScale = 1;
        const iconSize = Math.ceil(iconRatio * width * zoomScale);
        marker.setIcon(new L.Icon({
            iconUrl: returnCorrectImageUrl(markerData.marker_icon, "small"),
            iconSize: [iconSize, iconSize],
            iconAnchor: [iconSize / 2, iconSize / 2],
            popupAnchor: [0, -iconSize / 2]
        }));
        marker.on('click', () => {
            let updatedMarkerData = { ...markerData }
            updatedMarkerData.cityId = city._id
            updatedMarkerData.layerId = layerId
            setSelectedMarker(updatedMarkerData)
        })
        if(checkData[markerData._id]){

            if(reInitializeMap ){
                let markerOldData= visibleMarkers.current.findIndex(({ _id }) => _id==layerId+"_"+markerData._id)
                if(markerOldData<0){
                    const element = currentMapLayerElement[markerData._id];
                    
                    currentMapLayerElement[markerData._id] = { display: element.display, type: "marker", data: marker, parent: newLayer };
                    
        
                    if(element.display){
                        marker.addTo(newLayer);
                    }
                 
                }else{
                    marker.addTo(newLayer);
                    visibleMarkers.current.splice(markerOldData,1)
                    currentMapLayerElement[markerData._id] = { display: true, type: "marker", data: marker, parent: newLayer };
                    visibleMarkers.current.push({_id:layerId+"_"+markerData._id,marker:marker, x: markerData.coordinates.latitude * height, y: markerData.coordinates.longitude * width,index:currentMapLayerElement["index"]})
                    
                }
             }
             else{
                marker.addTo(newLayer);
                currentMapLayerElement[markerData._id] = { display: true, type: "marker", data: marker, parent: newLayer };
                visibleMarkers.current.push({_id:layerId+"_"+markerData._id,marker:marker, x: markerData.coordinates.latitude * height, y: markerData.coordinates.longitude * width,index:currentMapLayerElement["index"]})
             }
            
        }else{
            currentMapLayerElement[markerData._id] = { display: false, type: "marker", data: marker, parent: newLayer };
        }
        
        let markerOldData= allMarkers.findIndex(({ marker }) => marker._id==layerId+"_"+markerData._id)
        if(markerOldData>=0){
            allMarkers.splice(markerOldData,1)
        }
        allMarkers.push({_id:layerId+"_"+markerData._id,marker:marker, x: markerData.coordinates.latitude * height, y: markerData.coordinates.longitude * width,index:currentMapLayerElement["index"]})
        
        currentMapLayerElement["index"]=currentMapLayerElement["index"]+1
    };

    const updateMarkerPositions = (map, width) => {

        let currentMarkerNodes = []
        visibleMarkers.current.sort((a,b)=>a.index-b.index)
        
        visibleMarkers.current.forEach(function ({x,y}) {
            let physicalCoordinate = map.latLngToLayerPoint(L.latLng(x, y));
            let node = { x: physicalCoordinate.x, y: physicalCoordinate.y, r: radius }
            currentMarkerNodes.push(node);
        });

        var simulation = d3.forceSimulation()
            .force("collide", d3.forceCollide().radius(function (d) {
                return d.r + 1; // Add a small buffer
            }).iterations(0.1));

        simulation.nodes(currentMarkerNodes).tick();

        visibleMarkers.current.forEach(function ({marker}, i) {
            const zoomScale = 1 + (sizeGradient * map.getZoom())
            const iconSize = Math.ceil(Math.ceil(iconRatio * width) * zoomScale);
            marker.setIcon(new L.Icon({
                iconUrl: marker.options.icon.options.iconUrl,
                iconSize: [iconSize, iconSize],
                iconAnchor: [iconSize / 2, iconSize / 2],
                popupAnchor: [0, -iconSize / 2]
            }));
            let newCoordinate = currentMarkerNodes[i];
            marker.setLatLng(map.layerPointToLatLng(L.point(newCoordinate.x, newCoordinate.y)));
        });
    }

    const initializeMap = (width, height, reInitializeMap) => {
        const map = L.map(mapContainerRef.current, {
            crs: L.CRS.Simple,
            minZoom: 0,
            maxZoom: 4,
            maxBounds: L.latLngBounds([[0, 0], [height, width]])
        }).setView([width, height], 0);

        L.imageOverlay(city.baseMap.imageUrl, [[0, 0], [height, width]]).addTo(map);
        const overlays = {};
        for (let layer of city.layers) {
            let newLayer = L.layerGroup()
            overlays[layer.name] = newLayer;
            for (let marker of layer.markers) {
                addMarkerData(newLayer, marker, width, height, map, layer._id,reInitializeMap); // Assuming iconRatio is 0.05, adjust as needed
            }
            if(checkData[layer._id]){
                if(reInitializeMap ){
                   let layerData= visibleMarkers.current.filter(({ _id }) => _id.startsWith(layer._id))
                   if(layerData.length==0){
                    currentMapLayerElement[layer._id] = { display: false, type: "layer", data: newLayer };
                    continue
                   }
                }
                newLayer.addTo(map)
                currentMapLayerElement[layer._id] = { display: true, type: "layer", data: newLayer };
            }else
            currentMapLayerElement[layer._id] = { display: false, type: "layer", data: newLayer };
        }

        map.on('zoomend', () => updateMarkerPositions(map, width)); // Assuming iconRatio is 0.05, adjust as needed
        updateMarkerPositions(map, width); // Assuming iconRatio is 0.05, adjust as needed
        currentMapLayerElement["map"] = map;

        mapRef.current = map;
    };


    useEffect(() => {

        const { width, height, containerWidth } = calculateDimensions();
        if (mapContainerRef.current) {
            if (containerWidth) {
                mapContainerRef.current.style.width = `${containerWidth}px`;
            }
            else {
                mapContainerRef.current.style.width = `${width}px`;
            }

            mapContainerRef.current.style.height = `${height}px`;
        }

        if (currentMapLayerElement && currentMapLayerElement.cityId == city._id) {
            if (!mapRef.current) {
                mapContainerRef.current.appendChild(currentMapLayerElement["map"].getContainer())
                currentMapLayerElement["map"].getContainer().style.margin = '0px'
                //currentMapLayerElement["map"].getContainer().style.cursor=addMarker && addMarker.status && !addMarker.selected ? 'crosshair' : 'grab'
            }
            return
        }

        if (mapRef.current) {
            mapRef.current.remove();
            mapRef.current = null;
        }
        currentMapLayerElement.cityId = city._id
        initializeMap(width, height, false);

    }, [city]);

    useEffect(() => {
        const handleResize = () => {
            

            const { width, height, containerWidth } = calculateDimensions();
            if (mapContainerRef.current) {
                if (containerWidth) {
                    mapContainerRef.current.style.width = `${containerWidth}px`;
                }
                else {
                    mapContainerRef.current.style.width = `${width}px`;
                }

                mapContainerRef.current.style.height = `${height}px`;
            }
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
            for (let key in actualMarkerPos) {
                if (actualMarkerPos.hasOwnProperty(key)) {
                    delete actualMarkerPos[key];
                }
            }
            allMarkers.length = 0
            currentMapLayerElement.cityId = city._id
            initializeMap(width, height, true);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (!changedCheckBoxId) return;

        

        const element = currentMapLayerElement[changedCheckBoxId._id];

        if (element) {
            element.display = !element.display;
            const { width, height, containerWidth } = calculateDimensions(true);
            const map = currentMapLayerElement["map"];
            if (element.display && element.type == "marker") {
                element.parent.addLayer(element.data);
                visibleMarkers.current  = [...visibleMarkers.current,...allMarkers.filter(({ _id }) => _id.endsWith(changedCheckBoxId._id))];

                updateMarkerPositions(currentMapLayerElement["map"],width)
            }
            else if (element.display && element.type == "layer") {
                
                map.addLayer(element.data);
                
                visibleMarkers.current  = [...visibleMarkers.current,...allMarkers.filter(({ _id }) => _id.startsWith(changedCheckBoxId._id))];

                updateMarkerPositions(currentMapLayerElement["map"],width)
            }
            else if (element.type == "marker") {

                element.parent.removeLayer(element.data);
                visibleMarkers.current  = visibleMarkers.current.filter(({ _id }) => !_id.endsWith(changedCheckBoxId._id));
                updateMarkerPositions(currentMapLayerElement["map"],width)
            }
            else {
                map.removeLayer(element.data);
                
                visibleMarkers.current  = visibleMarkers.current.filter(({ _id }) => !_id.startsWith(changedCheckBoxId._id));
                updateMarkerPositions(currentMapLayerElement["map"],width)
            }
        }
        setChangedCheckBoxId(null)
    }, [changedCheckBoxId]);

    return (
        <div
            id="map"
            className="md:ml-8 md:mr-8"
            ref={mapContainerRef}
            style={{
                cursor: 'grab',
                zIndex: 0
            }}
        ></div>
    );
}