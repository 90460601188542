import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { FaImage, FaVideo } from 'react-icons/fa';
import postService from '../../services/PostService';
import SeePost from './Seepost';

const ShowPost = ({ markerId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [currentPost,setCurrentPost]=useState(null)
    const [postInfo,setPostInfo]=useState({index:0,length:0})
    const [openSeePost,setOpenSeePost]=useState(false)

    const handleOpenSeePost=(index)=>{
        let map=document.getElementById("map")
        if(map){
            map.style.zIndex=0
        }
        setCurrentPost(data[index])
        setPostInfo({index,length:data.length})
        setOpenSeePost(true)
    }

    const handleCloseSeePost=()=>{

        setOpenSeePost(false)
    }

    const handleOnNext=()=>{
        setCurrentPost(data[postInfo.index+1])
        setPostInfo({index:postInfo.index+1,length:data.length})
    }
    const handleOnPrev=()=>{
        setCurrentPost(data[postInfo.index-1])
        setPostInfo({index:postInfo.index-1,length:data.length})
    }

    const handlePostEdit=(post)=>{
        let updatedData=[...data]
        updatedData[postInfo.index]=post;
        setData(updatedData)
        setCurrentPost(post)
    }

    const handelDeletePost=()=>{
        setOpenSeePost(false)
        let updatedData=[...data]
        updatedData.splice(postInfo.index,1);
        setData(updatedData)
        setCurrentPost(null)
    }
    useEffect(() => {
        // Simulate a data fetching API call
        setLoading(true);
        postService.getPostByMarkerId(markerId).then((data) => {
            setData(data);
            setLoading(false);
        });
    }, [markerId]);


    return (
        <div className={`flex justify-center items-center ${loading && 'h-64'} bg-gray-100 w-full`}>
            {loading ? (
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
            ) : (
                <div className='w-full flex flex-wrap bg-white'>
                    {data.map((post, index) => (
                        <div key={index} className="relative w-1/4 aspect-square pointer-cursor" onClick={()=>{handleOpenSeePost(index)}}>
                            {post.type === 'image' ? (
                                <>
                                    <FaImage className="absolute top-2 right-2 m-1 text-white p-1" size={24} />
                                    <img src={post.fileUrl.medium.imageUrl} alt={`media-${index}`} className={`w-full h-full object-cover ${(index % 4 )< 3 && index !== data.length - 1 && 'border-r border-gray-800'} ${index > 3 && 'border-t border-gray-800'}`} />
                                </>
                            ) : (
                                <>
                                    <FaVideo className="absolute top-2 right-2 m-1 text-white p-1" size={24} />
                                    <video className={`w-full h-full object-cover ${index % 4 < 3 && index !== data.length - 1 && 'border-r border-gray-800'} ${index > 3 && 'border-t border-gray-800'}`}>
                                        <source src={post.fileUrl.large.imageUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        <SeePost
        currentPost={currentPost}
        onClose={handleCloseSeePost}
        isOpen={openSeePost}
        postInfo={postInfo}
        onNext={handleOnNext}
        onPrev={handleOnPrev}
        handlePostEditSuccess={handlePostEdit}
        handlePostDeleteSuccess={handelDeletePost}
        />
        </div>

    );
};

export default ShowPost;
