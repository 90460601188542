import React from 'react';
import PropTypes from 'prop-types';

const LayerAndMarkerText = ({ children }) => {
  const textStyle = {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12.46px',
    fontWeight: 'normal',
    lineHeight: '14.58px',
    letterSpacing: '0em',
    color: '#333333'
  };

  return (
    <span style={textStyle}>
      {children}
    </span>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayerAndMarkerText;
