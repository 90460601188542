
import './RightBar.css'


import { FaBars } from 'react-icons/fa6'
import userLogo from './user.svg'

import LogoutModal from './LogOutModal'
import { logout } from '../../services/AuthService'
import Map from './Map'
import Post from './Post'
import { useState } from 'react'
import AddInfluencer from './AddInfluencer'


const RightBar = ({active,setActive,setIsDrawerOpen,isLogOutOpen,setIsLogOutOpen}) => {

   
   


    const [isLogOutLoading,setIsLogOutLoading]=useState(false)
  


    const handleLogOut =async ()=>{
        setIsLogOutLoading(true)
        logout();
    }

    const handleLogOutClose=()=>{
        setIsLogOutOpen(false)
    }

   
   
   
    return (
        <div className='h-full flex flex-col'>
            <div className="flex w-full bg-white h-24 p-2 md:p-5 font-bold text-lg md:text-2xl items-center">
            <div className='md:hidden'>
                <FaBars size={40} className='text-[#343C6A] p-2' onClick={()=>{setIsDrawerOpen(true)}}/>
            </div>
            <div className='flex flex-1 text-[#343C6A] justify-center md:justify-start'>
                {active.maps && 'Maps'}
                {active.posts && 'All Posts'}
                {active.influencer && 'Add Influencer'}
            </div>
            <div >
                <img src={userLogo} className='rounded-full h-10 w-10 bg-[#F5F7FA] p-2'/>
            </div>
            </div>
            <div className='flex-1 w-full overflow-y-auto p-2 md:p-4'>
           <Map isOpen={active.maps}/>
           <Post isOpen={active.posts}/>
           <AddInfluencer isOpen={active.influencer}/>
          
           </div>
           <LogoutModal
             isOpen={isLogOutOpen}
             onClose={handleLogOutClose}
             onLogout={handleLogOut}
             loading={isLogOutLoading}
             />
        
        </div>
    )
}

export default RightBar