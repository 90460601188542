import './ShowMarkerInfo.css'
import { FaLocationArrow, FaPenToSquare, FaPlus } from "react-icons/fa6"
import no_image from "./no_image.png"
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';
import { useState } from 'react';
import AddPost from '../Post/AddPost';
import ShowPost from '../Post/ShowPost';

export default function ShowMarkerInfo({ editCityButton, selectedMarker, editMarker, setEditMarker }) {
    const [openAddPost, setOpenAddPost] = useState(false)
    let parent = document.getElementById("map");
    if(parent){
    parent.style.zIndex = 0;

    let child = parent.querySelector("#map");
    if (child) {
        child.style.zIndex = 0;
    }}


    const handleOpenAddPost = () => {
        setOpenAddPost(true)
        let parent = document.getElementById("map");
        parent.style.zIndex = 0;

        let child = parent.querySelector("#map");
        if (child) {
            child.style.zIndex = 0;
        }
    }

    const handleCloseAddPost = () => {
        setOpenAddPost(false)
        // let parent = document.getElementById("map");
        // parent.style.zIndex = 1;

        // let child = parent.querySelector("#map");
        // if (child) {
        //     child.style.zIndex = 1;
        // }
    }
    const handleSuccessfulAdd=()=>{
        handleCloseAddPost()
    }
    ////console.log(selectedMarker)
    const handleGetDirection = (link) => {
        window.open(link, '_blank'); // Replace 'https://www.example.com' with your external link
    };
    return (
        <div className="mx-6 mt-4">
            <div className="w-full flex pb-4 border-b-2">
                {selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") && <div className="w-1/3.5" style={{ maxWidth: '200px' }}>
                    <img src={selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") ? returnCorrectImageUrl(selectedMarker.thumbnail, "small") : no_image} className={`w-full h-full object-cover ${!returnCorrectImageUrl(selectedMarker.thumbnail, "small") && 'bg-gray-200 p-16'}`} style={{ aspectRatio: '1 / 1' }}></img>
                </div>}
                <div className="w-full flex">
                    <div className={`w-3/4 ${selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") && 'p-4'}`}>
                        <div className='flex items-center'>
                            <img src={returnCorrectImageUrl(selectedMarker.marker_icon, "small")} style={{ height: '70px', width: '70px' }}></img>
                            <span className='show-marker-info-marker-name'>{selectedMarker.name}</span>
                        </div>
                        <div className='show-marker-info-marker-caption mt-3'>
                            {selectedMarker.caption}
                        </div>
                    </div>
                    <div className="w-1/4 p-1">

                        {editCityButton && <p
                            onClick={() => { setEditMarker({ ...editMarker, status: true, cityId: selectedMarker.cityId, layerId: selectedMarker.layerId, markerId: selectedMarker._id, selected: true, data: selectedMarker }) }}
                            className="w-full m-1 flex p-1 items-center rounded justify-center custom-button"

                        >
                            <FaPenToSquare height={17} width={17} className="mr-2" />
                            Edit Marker
                        </p>}



                        <p
                            onClick={() => { handleGetDirection(selectedMarker.direction_url) }}
                            className="w-full flex m-1 p-1 items-center rounded justify-center custom-button"
                        >
                            <FaLocationArrow height={17} width={17} className="mr-2" />
                            Get Direction
                        </p>


                    </div>

                </div>
            </div>

            <div className="w-full flex pb-4 mt-4">
                <div className='flex justify-between w-full'>
                    <p
                        className="m-1 flex p-1 show-marker-info-marker-photos-reels-heading"
                    >
                        Top Reels and Posts
                    </p>
                    <div style={{ width: '25%' }} className='p-1'>
                        <p
                            onClick={handleOpenAddPost}
                            className="w-full m-1 flex p-1 items-center rounded justify-center custom-button"
                        >
                            <FaPlus height={17} width={17} className="mr-2" />
                            Add a Post
                        </p>
                    </div>
                </div>
            </div>
            {!openAddPost&&<ShowPost markerId={selectedMarker._id}/>}
            <AddPost
                isOpen={openAddPost}
                onCancel={handleCloseAddPost}
                cityId={selectedMarker.cityId}
                layerId={selectedMarker.layerId}
                markerId={selectedMarker._id}
                onSuccessAdd={handleSuccessfulAdd}
            />
        </div>
    )
}