import AddCity from "../City/AddCity";
import EditCityInfo from "../City/EditCityInfo";
import NoCitiesAvailable from "../City/NoCitiesAvailable";
import ShowCity from "../City/ShowCity";

const RightBar=({addCity,setAddCity,currentCity,editCity,setEditCity,selectedMarker,setSelectedMarker})=>{
    return(
        <div className="bg-white w-full h-full p-4 border-r-2 border-gray-300 shadow-lg overflow-y-auto">
        {addCity&&<AddCity setAddCity={setAddCity}/> }
        {editCity&&<EditCityInfo setEditCity={setEditCity} city={currentCity}/> }
        {!editCity && !addCity && currentCity && <ShowCity currentCity={currentCity} setEditCity={setEditCity} selectedMarker={selectedMarker} setSelectedMarker={setSelectedMarker}/>}
        {!editCity && !addCity && !currentCity && <NoCitiesAvailable/>}
        </div>
        
    )
}

export default RightBar;