import React, { useState } from 'react';
import { resetPassword } from '../../../../services/AuthService';

const ChangePasswordModal = ({ onClose, setSuccess,email }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handlePasswordChange = async () => {
        setLoading(true);
        if(oldPassword.length<5 || newPassword.length<5){
            setError("Password must be atleast 5 numbers long.")
            setLoading(false);
            return
        }
        try{
            await resetPassword({email,oldPassword,password:newPassword})
            setLoading(false)
            setNewPassword('')
            setOldPassword('')
            setSuccess(true)
        }
        catch(error){
            if (error.response?.status === 401) {
                setError('Invalid Old Password');
                setLoading(false)
                return
              }
             
              setError(error.response?.data?.error || 'An unexpected error occurred');
              setLoading(false)
        }

    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 p-2">
            <div className="bg-white p-4 rounded shadow-lg w-full md:w-96">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-bold">Change Password</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-3xl">
                        &times;
                    </button>
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 mb-2" htmlFor="oldPassword">Old Password</label>
                    <input
                        type="password"
                        id="oldPassword"
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 mb-2" htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        required
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
                <div className="mt-4 flex justify-center space-x-2">
                   
                    <button
                        onClick={handlePasswordChange}
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Changing...' : 'Change Password'}
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
