import { useNavigate } from "react-router-dom";


export default function Map({isOpen}){
const navigate=useNavigate()

    if(!isOpen){
        return null;
    }
    return (
        <div className="w-full p-5 flex-1 overflow-y-auto">
            <div className="bg-white rounded-3xl w-full mb-5 md:w-80 p-6 flex flex-col md:items-center"> 
                <p className="font-semibold text-base">Explore pre-made maps</p>
                <p className="w-40 mt-6 gradient-bg rounded-3xl text-white font-semibold text-center text-lg cursor-pointer" onClick={()=>{navigate("/admin")}}>Explore</p>
            </div>
        </div>
    )
}