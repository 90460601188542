// Modal.js
import React from 'react';

const SavedMapModal = ({ isOpen, onClose, mapName, onMapNameChange, onSave, onUpdate, error, currentMapName, setAlreadySaved,alreadySaved,loading }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 p-2">
            <div className="bg-white p-4 rounded shadow-lg w-full md:w-96">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-bold">Save Map</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-3xl">
                        &times;
                    </button>
                </div>
                {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
                <div>
                    <div className='flex items-center'>
                    <label className=" text-sm font-medium text-gray-700">
                        Map Name : 
                    </label>
                    {!alreadySaved && <input
                        type="text"
                        value={currentMapName}
                        onChange={onMapNameChange}
                        required
                        className="block flex-1 border border-gray-300 rounded p-2 ml-2"
                    />}

                    {alreadySaved && <p className='ml-2 font-semibold text-lg'>{mapName}</p>}</div>
                </div>
               
                <div className="mt-4 flex justify-end space-x-2">
                    {!alreadySaved && <button
                        onClick={onSave}
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save Map'}
                    </button>}

                    {alreadySaved && <button
                        onClick={() => { setAlreadySaved(null) }}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Save As New
                    </button>}
                    {alreadySaved && <button
                        onClick={onUpdate}
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Map'}
                    </button>}

                </div>
            </div>

        </div>
    );
};

export default SavedMapModal;
