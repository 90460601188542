import { useContext, useState } from "react"
import LeftBar from "./LeftBar"
import RightBar from "./RightBar"

const AdminDashBoard=()=>{
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [active,setActive]=useState({maps:true,posts:false,influencer:false,})
    const [isLogOutOpen,setIsLogOutOpen]=useState(false)
   
   
    return (<div className="flex flex-col md:flex-row w-full h-screen">
       
        <div className={`w-full  md:w-[250px]  bg-white ${!isDrawerOpen&&'hidden'} md:block ${isDrawerOpen&&'fixed left-0 right-0 top-0 left-0 inset-0 bg-white bg-opacity-50 z-10'}`} >
          
           <LeftBar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} active={active} setActive={setActive} isLogOutOpen={isLogOutOpen} setIsLogOutOpen={setIsLogOutOpen} />
        </div>
      
        <div className={`w-full h-full md:flex-1 bg-black" id="main-content overflow-y-auto bg-[#F5F7FA]` }>
       <RightBar active={active} setActive={setActive} setIsDrawerOpen={setIsDrawerOpen} isLogOutOpen={isLogOutOpen} setIsLogOutOpen={setIsLogOutOpen} />
        </div>
        </div>)
}

export default AdminDashBoard