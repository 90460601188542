
import { initiateUpload, uploadChunks, completeUpload } from './ImageUploader';


export default async function uploadVideo(file,isMarker,setUploadedpercentage) {
    //console.log("uploadVideo setUploadedpercentage",setUploadedpercentage)
   let uploadedData=await upload(file,setUploadedpercentage)
   return {large:uploadedData,isVideo:true}
}

async function upload(file,setUploadedpercentage) {
    const { fileId, chunkSize } = await initiateUpload(file);
    await uploadChunks(file, fileId, chunkSize,setUploadedpercentage);
    const { downloadUrl } = await completeUpload(fileId);
    return { fileId, imageUrl: downloadUrl,isVideo:true };
}