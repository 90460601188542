// src/router.js

import * as React from 'react';
import { createBrowserRouter, Link } from 'react-router-dom';
import Home from './views/Home/Home';
import EditCity from './views/City/EditCity'
import City from './user view src/views/City/City'
import { homeLoader } from './RouteLoader/authLoader';
import { CityProvider } from './Context/CityContext';
import { CityProviderForUser } from './user view src/Context/CityContext';
import SignUp from './views/Auth/SignUp';
import AdditionalInfo from './views/Auth/AdditionalInfo';
import ForgotPassword from './views/Auth/ForgotPassword/ForgotPassword';
import Dashboard from './views/Dashboard/Dashboard';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    loader: homeLoader,
  },
  {
    path: '/signup',
    element: <SignUp />,
    loader: homeLoader,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    loader: homeLoader,
  },

  {
    path: '/addinfo',
    element: <AdditionalInfo />,
  },
  {
    path: '/admin',
    element: <Dashboard />,
  },

  {
    path: '/city/:cityId',
    element:  (
      <CityProvider>
        <EditCity />
      </CityProvider>
    ), 
  },
  {
    path: '/:cityName',
    element:  (
      <CityProviderForUser>
        <City />
      </CityProviderForUser>
    ), 
  },
]);

export default router;
