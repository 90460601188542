import React, { useEffect, useState } from 'react';
import { FaAt, FaEyeSlash, FaLock } from 'react-icons/fa6';
import riitiiText from './../riitii_text.svg';
import riitiiLogo from './../riitii_logo.svg';
import LoadingButton from './LoadingButton';
import { forgotPassword } from '../../../services/AuthService';

const OtpVerification = ({ otp, setOtp, handleSubmit, loading, error, setOtpSent, setFound, email,password,setPassword }) => {
  const [timer, setTimer] = useState(60);
  const [resendOtp, setResendOtp] = useState(false);
  const [resendLoading, setResendLoading] = useState(false)
  const [showPassword, setShowPassword]=useState(false)

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendOtp(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleBack = () => {
    setOtpSent(false);
    setFound(false);
  };

  const handleResendOtp = async () => {
    if (resendOtp) {
      try {
        setResendLoading(true)
        setResendOtp(false);
        await forgotPassword(email)
        setResendLoading(false)
        setTimer(60);
        setResendOtp(false);
      }
      catch (err) {
        setResendOtp(true);
      }
    }
  };

  return (
    <div className="max-w-md w-full bg-white p-4 md:p-8">
      <div className='flex justify-center m-2 md:m-4'>
        <img src={riitiiText} alt="Riitii Text" />
      </div>
      <div className='rounded-lg p-4' style={{ boxShadow: '0 0 10px 3px #b2b3b8' }}>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 md:px-4 md:py-3 rounded relative" role="alert">
            <strong className="font-bold">Error! <span>{error}</span></strong>
          </div>
        )}
        <div className='flex justify-between items-center pt-2 pb-2 mb-2'>
          <p className='text-2xl font-bold ml-2'>Verify Your Account</p>
          <img src={riitiiLogo} alt="Riitii Logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div className="rounded-md shadow-sm space-y-4 ml-2 text-sm text-gray-400">
              We have sent an OTP to <br />
              {email}
            </div>
            <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md'>
              <FaLock size={17} className='mr-2 opacity-50' style={{ color: '#0B4654' }} />
              <label htmlFor="email" className="sr-only">Email</label>
              <input
                id="otp"
                name="otp"
                type="number"
                maxLength={6}
                required
                className="flex-1 appearance-none focus:outline-none sm:text-sm"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:z-10'>
            <FaLock size={17} className='mr-2 opacity-50' style={{color:'#0B4654'}}/>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type={showPassword?'text':'password'}
                required
                className="flex-1 appearance-none focus:outline-none  sm:text-sm"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FaEyeSlash size={17} className={`mr-2 ${showPassword?'opacity-100':'opacity-50'}`} style={{color:'#0B4654'}} onClick={()=>{setShowPassword(!showPassword)}}/>
                
            </div>
          </div>
          <div className='flex text-sm text-gray-400 items-center text-black mt-2 ml-2 mb-2'>
            <span className='mr-2'>Didn't receive OTP, </span>
            <span
              className={`text-[#07B464] cursor-pointer ${!resendOtp ? 'cursor-not-allowed text-gray-400' : ''}`}
              onClick={handleResendOtp}
              disabled={!resendOtp}
            >
              Resend OTP
            </span>
            <span className='ml-2'>{timer > 0 && `[${timer}]`}</span>
            <span className="flex items-center pl-3">
              {resendLoading ?
                (<div className="animate-spin rounded-full h-4 w-4 border-r-2 border-black opacity-100"></div>)
                :
                ''
              }
            </span>
          </div>
          <LoadingButton loading={loading} text={{ main: "Verify Otp", load: "Verifying" }} />
          <div className='flex justify-center items-center text-black mt-4'>
            <span className='text-[#07B464] text-base cursor-pointer' onClick={handleBack}>Go Back</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
