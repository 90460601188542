import React from 'react';
import { FaAt } from 'react-icons/fa6';
import riitiiText from './../riitii_text.svg';
import riitiiLogo from './../riitii_logo.svg';
import LoadingButton from './LoadingButton';

const EmailInput = ({ email, setEmail, handleSubmit, loading, error }) => {
  return (
    <div className="max-w-md w-full bg-white p-4 md:p-8">
      <div className='flex justify-center m-2 md:m-4'>
        <img src={riitiiText} alt="Riitii Text" />
      </div>
      <div className='rounded-lg p-4' style={{boxShadow:'0 0 10px 3px #b2b3b8'}}>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 md:px-4 md:py-3 rounded relative" role="alert">
            <strong className="font-bold">Error! <span>{error}</span></strong>
          </div>
        )}
        <div className='flex justify-between items-center pt-2 pb-2 mb-2'>
          <p className='text-2xl font-bold ml-2'>Find Your Account</p>
          <img src={riitiiLogo} alt="Riitii Logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md'>
              <FaAt size={17} className='mr-2 opacity-50' style={{color:'#0B4654'}} />
              <label htmlFor="email" className="sr-only">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                required
                className="flex-1 appearance-none focus:outline-none sm:text-sm"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <LoadingButton loading={loading} text={{main:"Find Account",load:"Finding"}} />
          <div className='flex justify-center items-center text-black mt-4'>
            <span className='text-[#07B464] text-base cursor-pointer ' onClick={() => { window.location.href="/" }}>Go Back</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailInput;
