import { useContext, useState } from "react";
import { FaArrowDown, FaArrowLeft, FaArrowUp, FaChevronDown, FaChevronUp, FaLayerGroup } from "react-icons/fa6";
import { CityContext } from "../../Context/CityContext";
import LayerAndMarkerText from "../../Components/Text/LayerAndMarkerText";
import returnCorrectImageUrl from "../../utils/returnCorrectImageUrl";
import './LeftBar.css'

export default function LeftBar({isLayerOpen, setIsLayerOpen,checkData,setCheckData }) {
    const { city, setChangedCheckBoxId } = useContext(CityContext)
    const [openNodes, setOpenNodes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCitiesListClick=()=>{
        window.location.href = 'https://www.riitii.com/explore-cities';
    }

    const handleIsLayerOpen=()=>{
        setIsLayerOpen(!isLayerOpen)
    }

    const filteredFileTreeData = city.layers
    .map((layer) => {
        const filteredMarkers = layer.markers.filter((marker) =>
            marker.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Return the layer only if it has matching markers or if the layer itself matches the search term
        if (layer.name.toLowerCase().includes(searchTerm.toLowerCase()) || filteredMarkers.length > 0) {
            return {
                ...layer,
                markers: filteredMarkers
            };
        }
        
        // Return null if the layer does not match and has no matching markers
        return null;
    })
    .filter((layer) => layer !== null);

    const handleCheckboxChange=(node_id)=>{
        let checkedData={...checkData}
        checkedData[node_id]=!checkedData[node_id]
        setCheckData(checkedData)
        setChangedCheckBoxId({ _id: node_id, display: !checkedData[node_id] })
    }


    const TreeNode = ({ node, level = 0, parent = null }) => {
        const isOpen = openNodes.includes(node._id);

        const handleToggle = () => {
            if (isOpen) {
                setOpenNodes(openNodes.filter(id => id !== node._id));
            } else {
                setOpenNodes([...openNodes, node._id]);
            }
        };


        return (
            <li
                className={`ml-${level * 2} ${parent && !checkData[parent] && "bg-gray-200 pointer-events-none opacity-50"}`}

            >
                <div className="flex items-center cursor-pointer py-1 relative custom-checkbox-user">
                

                    <input type="checkbox" className="mr-2 relative" checked={checkData[node._id]} disabled={false || (parent && !checkData[parent])} onChange={() => handleCheckboxChange(node._id)} />
                    {!node.markers && <img src={returnCorrectImageUrl(node.marker_icon, "small")} alt="Base Map" className="mr-2 w-5 h-5 object-cover rounded-md border border-gray-300" />}

                    {node.markers ? (<span className={`font-semibold text-gray-800 truncate flex-1 ${!parent && !checkData[node._id] && 'pointer-events-none opacity-50'}`} onClick={handleToggle}>{node.name}</span>) : (
                        <LayerAndMarkerText className="font-semibold text-gray-800 truncate">{node.name}</LayerAndMarkerText>)}
                        {node.markers && (
                        isOpen ? (
                            <span onClick={handleToggle}>
                                <FaChevronUp style={{ width: '18px', height: '18px', color: '#1C5B7D' }} className="ml-1" />
                            </span>
                        ) : (
                            <span onClick={handleToggle}>
                                <FaChevronDown style={{ width: '18px', height: '18px', color: '#1C5B7D' }} className="ml-1" />
                            </span>
                        )
                    )}

                </div>
                {isOpen && node.markers && (
                    <ul className={`ml-${level * 2} pl-4`} style={{backgroundColor:"#F2FAFF",marginLeft:'-8px',marginRight:'-8px'}}>
                        {node.markers.map((markerNode, index) => (
                            <TreeNode key={index} node={markerNode} level={level + 1} parent={node._id} />
                        ))}
                    </ul>
                )}
            </li>
        );
    };

    return (
        <div className={`flex flex-col ${isLayerOpen&&'h-full'} bg-white md:h-screen border-gray-400 md:border-r`}>
             <div onClick={handleCitiesListClick}>
             {!isLayerOpen&&<div className='go-back-button m-2' >
                <FaArrowLeft style={{ width: '15px', height: '15px', color: '#fff' }} />
                <span>Cities List</span>
            </div>}
            </div>
            <div className={`${!isLayerOpen&&'hidden'} md:block px-2 border-l-2 border-r-2 md:border-0 color-border`}>
            <div className="flex items-center justify-between mb-4 pt-2 search-header">
                <h2>Select Places for your Itinerary</h2>
            </div>

            <div className="mb-4">
                <input
                    type="text"
                    className="w-full h-8 p-2 border border-gray-300 rounded-md"
                    placeholder="Search layers..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            </div>

            <ul className={`flex-1 overflow-y-auto ${!isLayerOpen&&'hidden'} md:block px-2 color-border border-l-2 border-r-2 md:border-0`}>
                {filteredFileTreeData.map((rootNode, index) => (
                    <TreeNode key={index} node={rootNode} />
                ))}
            </ul>
           
            <div className={`block md:hidden p-2 text-white w-full flex justify-center items-center cursor-pointer`} id="toggle-button" onClick={handleIsLayerOpen} style={{backgroundColor:'#2B688E'}}>
                <FaLayerGroup size={20} className="mr-2" />
                Layer List
                {isLayerOpen ? <FaArrowUp className="mr-2" size={20} /> : <FaArrowDown className="ml-2" size={20} />}
            </div>
        </div>)
}