function setWithExpiry(key, value, ttl) {
    const now = new Date();
  
    // Create an object to store the value and the expiry time
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
  
    // Save the item as a string in localStorage
    localStorage.setItem(key, JSON.stringify(item));
  }
 
function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    localStorage.removeItem(key);
  
    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
  
    // Parse the item back into an object
    const item = JSON.parse(itemStr);
    const now = new Date();
  
    // If the item has expired, remove it from storage and return null
    if (now.getTime() > item.expiry) {
      
      return null;
    }
    
  
    return item.value;
  }
  
module.exports={setWithExpiry,getWithExpiry}
  
  