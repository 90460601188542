import React, { useState, useEffect } from 'react';
import userService from '../../../services/UserService';
import SuccessModal from '../SavedMap/SuccessModal';


const EmailVerificationForm = ({ isOpen, data }) => {
    const [email, setEmail] = useState(data.email);
    const [verified,setVerified]=useState(data.isVerified)
    const [otp, setOtp] = useState('')
    const [otpSent, setOtpSent] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);
    const [error, setError] = useState('');
    const [resendLoading,setResendLoading]=useState(false)
    const [success,setSuccess]=useState(false)
    
    const [loading,setLoading]=useState(false)

    const [timer, setTimer] = useState(0);

    const handleSuccessClose=()=>{
        setOtpSent(false)
        setVerified(true)
    }
    useEffect(() => {
        let interval;
        if (otpSent && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendOtp(true);
        }

        return () => clearInterval(interval);
    }, [timer]);

    if (!isOpen) return null

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSendOtp = async() => {
        setLoading(true)
        try{
            await userService.intiateProfileVerification();
            setError('')
            setOtpSent(true)
            setTimer(60)
            setResendOtp(false)
            setLoading(false)
        }
        catch(error){
              setError(error.response?.data?.error || 'An unexpected error occurred');
              setLoading(false)
        }
        

        //setOtpSent(true);
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleResendOtp = async () => {
        if (resendOtp) {
          try {
            setResendLoading(true)
            setResendOtp(false);
            await userService.intiateProfileVerification();
            setResendLoading(false)
            setTimer(60);
            
          }
          catch (err) {
            setResendOtp(true);
          }
        }
      };
    const handleVerifyOtp = async () => {
        setLoading(true)
        if(otp.length!=6){
            setError("Error! OTP must be 6 numbers long.")
            setLoading(false)
            return
        }
        try{
            await userService.verifyProfile(otp)
            setLoading(false)
            setError('')
            setSuccess(true)
        }
        catch(error){
            if (error.response?.status === 401) {
                setError('Invalid OTP');
                setLoading(false)
                return
              }
             
              setError(error.response?.data?.error || 'An unexpected error occurred');
              setLoading(false)
        }
    };


    const SendOtp = () => {
        if (otpSent) return null
        return (
            <form className='pl-4 md:pl-8 pt-4'>
                <div className='md:w-[400px]'>
               
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        disabled
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}

                    />
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                {!verified ?
                <button
                    type="button"
                    onClick={handleSendOtp}
                    disabled={loading}
                    className={`bg-[#2B688E] ${loading && 'bg-gray-200'} py-2 px-4 font-semibold font-base text-white rounded-lg mt-2 min-w-[110px]`}
                >

                    {loading ?'Sending...':'Send Code'}
                </button>:
                <p>Status: <span className='text-[#15B15C]'>Verified</span></p>
                }


            </form>
        )
    }
    const VerifyOtp = () => {
        if (!otpSent) return null
        return (
            <form className='pl-4 md:pl-8 pt-4'>
                <div className="rounded-md shadow-sm space-y-4 mb-2 text-sm text-gray-400">
              We have sent an OTP to {email}
            </div>
                <div className='md:w-[400px]'>
                    <label htmlFor="otp">OTP</label>
                    <input
                        id="otp"
                        name="otp"
                        type="number"
                        required

                        placeholder="Enter OTP"
                        value={otp}
                        onChange={handleOtpChange}

                    />
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <div className='flex text-sm text-gray-400 items-center text-black mt-2 ml-2 mb-2'>
                
            <span className='mr-2'>Didn't receive OTP, </span>
            <span
              className={`text-[#07B464] cursor-pointer ${!resendOtp ? 'cursor-not-allowed text-gray-400 font-bold' : ''}`}
              onClick={handleResendOtp}
              disabled={!resendOtp}
            >
              Resend OTP
            </span>
            <span className='ml-2'>{timer > 0 && `[${timer}]`}</span>
            <span className="flex items-center pl-3">
              {resendLoading ?
                (<div className="animate-spin rounded-full h-4 w-4 border-r-2 border-black opacity-100"></div>)
                :
                ''
              }
            </span>
          </div>
                <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className={`bg-[#2B688E] ${loading && 'bg-gray-200'} py-2 px-4 font-semibold font-base text-white rounded-lg mt-2 min-w-[110px]`}
                >
                    {loading ?'Verifying...':'Verify'}
                </button>
<SuccessModal isOpen={success} onClose={handleSuccessClose} message={"Profile is verified Successfully"}/>

            </form>
        )
    }


    return (
        <div className='w-full h-full'>
            {SendOtp()}
            {VerifyOtp()}
        </div>
    );
};


export default EmailVerificationForm;
