import MarkerIconService from '../services/MarkerIconService';
import { initiateUpload, uploadChunks, completeUpload } from './ImageUploader';
import resizeImage from './ResizeImage';

export default async function uploadImage(file,isMarker,setUploadedpercentage) {

    async function upload(file) {
        const uploadPercent=(data)=>{
            setUploadedpercentage(prev+Math.floor(data*33/100))
        }
        const { fileId, chunkSize } = await initiateUpload(file);
        await uploadChunks(file, fileId, chunkSize,uploadPercent);
        const { downloadUrl } = await completeUpload(fileId);
        return { fileId, imageUrl: downloadUrl };
    }

    let prev=0;
    const [smallImage, mediumImage] = await Promise.all([
        resizeImage(file, 20),
        resizeImage(file, 40)
    ]);

    const [smallImageResult, mediumImageResult, largeImageResult] = await Promise.all([
        upload(smallImage),
        upload(mediumImage),
        upload(file)
    ]);
    let  imageUrl={
        small: smallImageResult,
        medium: mediumImageResult,
        large: largeImageResult
    }
    
    if(isMarker){
        await MarkerIconService.createMarkerIcon({cityId:isMarker.cityId,imageUrl})
    }
    return imageUrl;


}


