import React, { createContext, useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import cityService from '../services/CityService';

export const CityContext = createContext();

export const CityProvider = ({ children }) => {
    const { cityId } = useParams();
    const [city, setCity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(null);
    const [currentCheckedChangeId,setCurrentCheckedChangeId]=useState(null)
    const [addMarker,setAddMarker]=useState(null)
    const [editMarker,setEditMarker]=useState(null)
    const currentMapLayerElement = useRef({}).current;
    const actualMarkerPos = useRef({}).current;
    const allMarkers = useRef([]).current;
    const [selectedMarker,setSelectedMarker]=useState(null)

    ////console.log("editMarker",editMarker)

    useEffect(() => {
        const fetchCity = async () => {
            ////console.log("here")
            try {
                const cityData = await cityService.getCityById(cityId);
                let checkData = {};
                for (let layer of cityData.layers) {
                    checkData[layer._id] = true;
                    for (let marker of layer.markers) {
                        checkData[marker._id] = true;
                    }
                }
                cityData.isChecked=checkData
                setIsChecked(checkData);
                setCity(cityData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCity();
    }, [cityId]);

    return (
        <CityContext.Provider value={{currentMapLayerElement,actualMarkerPos, allMarkers,city, setCity, loading, error, isChecked, setIsChecked,currentCheckedChangeId,setCurrentCheckedChangeId,addMarker,setAddMarker,editMarker,setEditMarker,selectedMarker,setSelectedMarker }}>
            {children}
        </CityContext.Provider>
    );
};
