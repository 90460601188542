// UpdateLayerModal.js
import React, { useState, useEffect } from 'react';
import LayerService from '../../services/LayerService';

const UpdateLayerModal = ({ cityId, layer, isOpen, onClose, onUpdateSuccess }) => {
    
    const [updatedLayerName, setUpdatedLayerName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (layer && layer.name) {
            setUpdatedLayerName(layer.name);
        }
    }, [layer]);

    const handleUpdateLayer = async () => {
        if (!updatedLayerName) return;

        setLoading(true);
        try {
            const response = await LayerService.updateLayer(cityId, layer.layerId, { name: updatedLayerName });
            onUpdateSuccess(response.data,'Layer updated'); // Notify parent component of successful update
            setError(null)
            onClose(); // Close the modal
        } catch (error) {
            setError('Failed to update layer. Please try again.'); // Set error message
            console.error('Error updating layer:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
            <div className="bg-white p-6 rounded-lg z-10 w-96">
                <h2 className="text-lg font-semibold mb-4">Update Layer</h2>
                <input
                    type="text"
                    value={updatedLayerName}
                    onChange={(e) => setUpdatedLayerName(e.target.value)}
                    placeholder="Enter updated layer name"
                    className="border border-gray-300 px-3 py-2 rounded-md mb-4 w-full"
                />
                {error && (
                    <div className="text-red-600 mb-4">{error}</div>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleUpdateLayer}
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Layer'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateLayerModal;
