import axiosInstance from "../utils/AxiosInterceptor";

const MarkerIconService = {
    // CREATE
    createMarkerIcon: (markerIconData) => {
      return axiosInstance.post('/marker-icons', markerIconData);
    },
  
    // READ ALL
    getAllMarkerIcons: () => {
      return axiosInstance.get('/marker-icons');
    },
  
    // READ ONE
    getMarkerIconById: (id) => {
      return axiosInstance.get(`/marker-icons/${id}`);
    },
  
    // READ BY CITY ID
    getMarkerIconByCityId: (cityId) => {
      return axiosInstance.get(`/marker-icons/city/${cityId}`);
    },
  
    // UPDATE
    updateMarkerIcon: (id, markerIconData) => {
      return axiosInstance.put(`/marker-icons/${id}`, markerIconData);
    },
  
    // DELETE
    deleteMarkerIcon: (id) => {
      return axiosInstance.delete(`/marker-icons/${id}`);
    }
  };
  
  export default MarkerIconService;