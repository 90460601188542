import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { FaTimes} from 'react-icons/fa'
import './SeePost.css';
import insta from './insta.svg';


const SeePost = ({ currentPost, onClose, onPrev, onNext,isOpen,postInfo }) => {
  

    if(!isOpen) return null;
    const handleVisitInstagram = (link) => {
        window.open(link, '_blank'); // Replace 'https://www.example.com' with your external link
    };
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 w-full h-full flex items-center justify-center">
            <div className="relative flex pointer-cursor h-3/4 bg-white">
                {currentPost.type == 'image' ? (
                    <img src={currentPost.fileUrl.large.imageUrl} className="w-auto h-full object-cover max-w-[50vw] min-w-[300px]" />
                ) : (
                    <video key={currentPost.fileUrl.large.imageUrl} controls loop autoPlay className="w-full h-full object-cover max-w-[50vw] min-w-[300px]">
                        <source src={currentPost.fileUrl.large.imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                
               
            </div>
            
            <div className="relative min-w-[300px] h-3/4 bg-white border-l-2 border-gray-400">
                <div className="relative h-full p-4">
                    <p className='heading ml-2 flex items-center mb-2'>
                        <img src={insta} width={32} height={32} className='mr-1' />
                        {currentPost.username}
                    </p>
                    <p className='profile-link ml-2 px-4 py-2 mt-2' onClick={()=>{handleVisitInstagram(currentPost.instagramUrl)}}>Visit Instagram</p>
                </div>
                
            </div>

            <button 
                    className="absolute top-4 right-4 text-white text-2xl p-2"
                    onClick={onClose}>
                    <FaTimes />
                </button>
                
                <button 
                    className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index<=0 && 'pointer-events-none opacity-50'}`}
                    onClick={onPrev}>
                    <FaChevronLeft />
                </button>
                
                <button 
                    className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index==postInfo.length-1 && 'pointer-events-none opacity-50'}`}
                    onClick={onNext}>
                    <FaChevronRight />
                </button>

        </div>
    );
}

export default SeePost;
