import { useEffect, useState } from 'react';
import './SavedMap.css'
import ShowSavedMap from './ShowSavedMap';
export default function SavedMap({isOpen}){
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, [userId]);
    console.log(userId)

    if(!isOpen){
        return null;
    }
    return (
        <div className="w-full p-5 flex-1 overflow-y-auto">
            <div className="bg-white rounded-3xl w-full mb-5 md:w-80 p-6 flex flex-col md:items-center"> 
                <p className="font-semibold text-base">Explore pre-made maps</p>
                <p className="w-40 mt-6 gradient-bg rounded-3xl text-white font-semibold text-center text-lg cursor-pointer" onClick={()=>{window.location.href="https://www.riitii.com/explore-cities"}}>Explore</p>
            </div>
            <div className="md:border-b-2 md:border-gray-200"></div>
            <p className="font-semibold">My Saved Maps</p>
            <ShowSavedMap userId={userId}/>
        </div>
    )
}