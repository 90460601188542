import React, { useState, useEffect } from 'react';
import { FaMapLocationDot, FaPenToSquare, FaLayerGroup, FaLocationDot, FaChevronRight, FaChevronDown, FaPlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import LayerAndMarkerText from '../../components/Text/LayerAndMarkerText';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';


const renderEditIcon = (cityId) => (
  <span className="ml-auto">
    <Link to={`/city/${cityId}`}>
      <FaPenToSquare className="text-gray-400 hover:text-gray-600 cursor-pointer" />
    </Link>
  </span>
);

const LeftBar = ({ addCity, setAddCity, cities, setCurrentCity ,setSelectedMarker}) => {

  const [fileTreeData, setFileTreeData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate();
    const handleLogout = () => {
        // Clear the accessToken and refreshToken from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Navigate to the root route
        navigate('/');
    };

  // Fetch the cities data when the component mounts
  useEffect(() => {
    setFileTreeData(cities);
    if (cities && cities.length > 0) {
      setCurrentCity(cities[0]);
      setSelectedCity(cities[0]);
    }
  }, [cities, setCurrentCity]);

  const handleAddCity = (event) => {
    setAddCity(!addCity);
  };

  const handleSelectCity = (city) => {
    setSelectedMarker(null);
    setCurrentCity(city);
    setSelectedCity(city);
  };

  // Recursive component to render file tree nodes
  const TreeNode = ({ node, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const isSelected = selectedCity && selectedCity._id === node._id;

    if (node.layers) {
      return (
        <li className={`ml-${level * 2}`}>
          <div className={`flex items-center cursor-pointer py-1 relative ${isSelected ? 'bg-gray-200' : ''}`}>
            {isOpen ? (
              <span onClick={handleToggle}>
                <FaChevronDown style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
              </span>
            ) : (
              <span onClick={handleToggle}>
                <FaChevronRight style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
              </span>
            )}
            <span className="font-semibold text-gray-800 truncate" onClick={() => handleSelectCity(node)}>{node.name}</span>
            {renderEditIcon(node._id)}
          </div>
          {isOpen && (
            <ul className={`ml-${level * 2} border-gray-300 pl-2`}>
              {node.layers.map((layerNode, index) => (
                <TreeNode key={index} node={layerNode} level={level + 1} />
              ))}
            </ul>
          )}
        </li>
      );
    } else if (node.markers) {
      return (
        <div>
          <div className={`flex items-center cursor-pointer py-1 relative ${isSelected ? 'bg-gray-200' : ''}`}>
            {isOpen ? (
              <span onClick={handleToggle}>
                <FaChevronDown style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
              </span>
            ) : (
              <span onClick={handleToggle}>
                <FaChevronRight style={{ width: '6px', height: '6px', color: '#1C5B7D' }} className="mr-1" />
              </span>
            )}
            <span className="font-semibold text-gray-800 truncate" onClick={handleToggle}>{node.name}</span>
          </div>
          {isOpen && (
            <ul className={`ml-${level * 2} pl-2`}>
              {node.markers.map((markerNode, index) => (
                <TreeNode key={index} node={markerNode} level={level + 1} />
              ))}
            </ul>
          )}
        </div>
      );
    } else {
      return (
        <li className={`ml-${level * 2} flex items-center py-1 ${isSelected ? 'bg-gray-200' : ''}`} >
          <img src={returnCorrectImageUrl(node.marker_icon,"small")} alt="Base Map" className="mr-2 w-5 h-5 object-cover rounded-md border border-gray-300" />


          <LayerAndMarkerText className="font-semibold text-gray-800 truncate">{node.name}</LayerAndMarkerText>
        </li>
      );
    }
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="bg-white w-full h-full p-4 border-r-2 border-gray-300 shadow-lg overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold text-gray-800">Cities</h2>
        {!addCity && (
          <span className="ml-auto">
            <FaPlus className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={handleAddCity} />
          </span>
        )}
      </div>
      <ul>
        {fileTreeData && fileTreeData.map((rootNode, index) => (
          <TreeNode key={index} node={rootNode} />
        ))}
      </ul> 
      <div className="absolute bottom-0 left-0 bg-white border-r border-gray-300 w-1/4 pt-2 pl-6 pr-6 pb-2">
                <button
                    onClick={handleLogout}
                    className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out flex items-center justify-center"
                >
                    <FaSignOutAlt className="mr-2" />
                    Logout
                </button>
            </div>

    </div>
  );
};

export default LeftBar;
