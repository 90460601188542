import React, { useState, useEffect } from 'react';
import LayerService from '../../services/LayerService';

const CreateLayerModal = ({ cityId, isOpen, onClose, onCreateSuccess }) => {
    const [newLayerName, setNewLayerName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null); // Reset error state when modal opens
    }, [isOpen]);

    const handleCreateLayer = async () => {
        if (!newLayerName) return;

        setLoading(true);
        try {
            const response = await LayerService.createLayer(cityId, { name: newLayerName });
            onCreateSuccess(response.data,'Layer created'); // Notify parent component of successful creation
            onClose(); // Close the modal
            setNewLayerName(''); // Reset input field
            setError(null)
        } catch (error) {
            setError('Failed to create layer. Please try again.'); // Set error message
            console.error('Error creating layer:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
            <div className="bg-white p-6 rounded-lg z-10 w-96">
                <h2 className="text-lg font-semibold mb-4">Create New Layer</h2>
                <input
                    type="text"
                    value={newLayerName}
                    onChange={(e) => setNewLayerName(e.target.value)}
                    placeholder="Enter layer name"
                    className="border border-gray-300 px-3 py-2 rounded-md mb-4 w-full"
                />
                {error && (
                    <div className="text-red-600 mb-4">{error}</div>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleCreateLayer}
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Layer'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateLayerModal;
