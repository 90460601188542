// DeleteMapModal.js
import React from 'react';

const DeleteSavedMapModal = ({ isOpen, onClose, onDelete, error, name }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-2">
      <div className="bg-white p-4 rounded shadow-lg w-full md:w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Delete Map</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-3xl">
            &times;
          </button>
        </div>
        <div>
          <p>Are you sure you want to delete saved map <b>{name}</b>?</p>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSavedMapModal;
