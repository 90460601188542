export default function Feedback({isOpen}){
    if(!isOpen){
        return null;
    }
    return (
        
            <div className="w-full h-full flex flex-col items-center pt-16">
                    <img src="https://riitii.s3.ap-south-1.amazonaws.com/1720680916966.svg" className="h-32 w-32 object-cover"/>
                    <p className="w-40 mt-6 gradient-bg rounded-3xl text-white font-semibold text-center text-lg cursor-pointer" onClick={()=>{window.open("https://forms.gle/7apds7DFFtpniYrc8","_blank")}}>Give Feedback</p>

                </div>

      
    )
}