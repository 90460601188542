import axios from 'axios';

// Create a new Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL?process.env.REACT_APP_BASE_URL:"https://map.riitii.com/api/", // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Login function
export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post('/users/login', { email, password });
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('userType', response.data.userType);
    localStorage.setItem('userId', response.data.userId);
    localStorage.setItem('profileImageUrl',JSON.stringify(response.data.profileImageUrl))
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Logout function
export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userType');
  localStorage.removeItem('userId');
  localStorage.removeItem('profileImageUrl')

  window.location.href='/'

};

// Get reset token function
export const getResetToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axiosInstance.get(
      '/users/resetAccessToken',
      {
        headers: {
          'x-refresh-token': refreshToken,
        },
      }
    );

    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
  
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const signup = async (email, password) => {
  try {
    const response = await axiosInstance.post('/users/register', { email, password });
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('userType', response.data.userType);
    localStorage.setItem('userId', response.data.userId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/users/forgotPassword', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async ({email,otp,oldPassword,password}) => {
  try {
    const response = await axiosInstance.post('/users/resetPassword', { email,otp,oldPassword,password });
    return response.data;
  } catch (error) {
    throw error;
  }
};




