import React, { useState, useEffect, forwardRef } from 'react';
import { FaImage, FaVideo } from 'react-icons/fa';
import insta from './insta.svg';

const InfluencerImageOrVideoPreview = forwardRef(({ src, type, username, instagramUrl }, ref) => {
  const [loaded, setLoaded] = useState(false);

  const handleVisitInstagram = () => {
    if (instagramUrl) {
      window.open(instagramUrl, '_blank');
    }
  };

  useEffect(() => {
    const handleLoad = () => setLoaded(true);

    if (type === 'image') {
      const img = new Image();
      img.src = src;
      img.onload = handleLoad;
    } else if (type === 'video') {
      const video = document.createElement('video');
      video.src = src;
      video.onloadeddata = handleLoad;
    }
  }, [src, type]);

  return (
    <div className={`relative flex items-center w-full ${loaded ? 'h-full max-h-[75vh] min-h-[60vh] md:aspect-square md:h-auto md:min-h-0' : 'aspect-square'} bg-[#28231D]`}>
      {!loaded && ( 
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-gray-800"></div>
        </div>
      )}
      {type === 'image' ? (
        <>
          <FaImage className="absolute top-2 right-2 m-1 text-white p-1" size={24} />
          
          <img
            src={src}
            ref={ref}
            onLoad={() => setLoaded(true)}
            alt="Media content"
            className={`w-full h-full object-cover ${loaded ? 'block' : 'hidden'}`}
          />
          <div className='absolute top-2 left-2 m-1 text-white p-1 cursor-pointer' onClick={handleVisitInstagram}>
            <p className='flex items-center'>
              <img src={insta} width={24} height={24} className='mr-1' />
              {username}
            </p>
          </div>
        </>
      ) : (
        <>
          <FaVideo className="absolute top-2 right-2 m-1 text-white p-1" size={24} />
        
          <video
            ref={ref}
            src={src}
            controls
            loop
            onLoadedData={() => setLoaded(true)}
            className={`w-full h-full max-h-[75vh] object-cover ${loaded ? 'block' : 'hidden'} md:hidden`}
          />
           <video
            src={src}
            onLoadedData={() => setLoaded(true)}
            className={`w-full h-full object-cover ${loaded ? 'block' : 'hidden'} hidden md:block`}
          />
            <div className='absolute top-2 left-2 m-1 text-white p-1 cursor-pointer' onClick={handleVisitInstagram}>
            <p className='flex items-center'>
              <img src={insta} width={24} height={24} className='mr-1' />
              {username}
            </p>
          </div>
        </>
      )}
    </div>
  );
});

export default InfluencerImageOrVideoPreview;
