import axiosInstance from "../utils/AxiosInterceptor";

const cityService = {
  // Retrieve all cities
  getCityByName: async (cityName) => {
    try {
      const response = await axiosInstance.get(`/city/name/${cityName}`);
      return response.data;
    } catch (error) {
      console.error('Error retrieving cities:', error);
      throw error;
    }
  },
}
  export default cityService