import { getResetToken } from "../services/AuthService";

export const homeLoader = async () => {
  try {
    const data = await getResetToken();
    ////console.log({ authenticated: true, data })
    return { authenticated: true, data };
  } catch (error) {
    return { authenticated: false };
  }
};
