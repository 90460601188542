import React from 'react';

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {/* Mobile View */}
      <div className="block md:hidden text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-lg text-gray-600 mb-4">Page Not Found</p>
        <a href="/" className="text-blue-500 hover:underline">
          Go back to the homepage
        </a>
      </div>
      
      {/* Desktop View */}
      <div className="hidden md:block text-center">
        <h1 className="text-8xl font-bold text-gray-800 mb-8">404</h1>
        <p className="text-2xl text-gray-600 mb-8">Sorry, the page you are looking for does not exist.</p>
        <a href="/" className="text-xl text-blue-500 hover:underline">
          Go back to the homepage
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
