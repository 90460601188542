import './MarkerInfo.css'
import { FaLocationArrow, FaPenToSquare, FaPlus } from "react-icons/fa6"
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';
import { useState } from 'react';
import ShowPost from '../Post/ShowPost';
import AddPost from '../InfluencerPost/AddPost';

export default function MarkerInfo({ selectedMarker }) {
    const [openAddPost, setOpenAddPost] = useState(false)
    let parent = document.getElementById("map");
    let userType=localStorage.getItem("userType")
    if (parent) {
        parent.style.zIndex = 0;

        let child = parent.querySelector("#map");
        if (child) {
            child.style.zIndex = 0;
        }
    }

    const handleOpenAddPost = () => {
        setOpenAddPost(true)
        let parent = document.getElementById("map");
        parent.style.zIndex = 0;

        let child = parent.querySelector("#map");
        if (child) {
            child.style.zIndex = 0;
        }
    }

    const handleCloseAddPost = () => {
        setOpenAddPost(false)
        // let parent = document.getElementById("map");
        // parent.style.zIndex = 1;

        // let child = parent.querySelector("#map");
        // if (child) {
        //     child.style.zIndex = 1;
        // }
    }
    const handleSuccessfulAdd=()=>{
        handleCloseAddPost()
    }



    ////console.log(selectedMarker)
    const handleGetDirection = (link) => {
        window.open(link, '_blank'); // Replace 'https://www.example.com' with your external link
    };
    return (
        <div className="md:mx-6 md:mt-4">
            <div className="w-full flex md:pb-4 md:border-b-2">
                {selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") && <div className="hidden md:w-1/3.5 md:block" style={{ maxWidth: '200px' }}>
                    <img src={returnCorrectImageUrl(selectedMarker.thumbnail, "small")} className={`w-full h-full object-cover ${!returnCorrectImageUrl(selectedMarker.thumbnail, "small") && 'bg-gray-200 p-16'}`} style={{ aspectRatio: '1 / 1' }}></img>
                </div>}
                <div className="w-full flex flex-col md:flex-row">
                    <div className={`w-full md:w-3/4 ${selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") && 'p-2 md:p-4'} ${!selectedMarker.thumbnail&&'p-2 md:p-0'}`}>
                        <div className='flex items-center'>
                            <img src={returnCorrectImageUrl(selectedMarker.marker_icon, "small")} className='w-14 h-14 md:w-16 md:h-16'></img>
                            <div>
                                <p className='show-marker-info-marker-name-user ml-2 md:ml-4'>{selectedMarker.name}</p>
                                <div className='ml-2 md:hidden '>
                                    <p
                                        onClick={() => { handleGetDirection(selectedMarker.direction_url) }}
                                        className="inline-flex p-1 items-center rounded justify-center custom-button-user"
                                    >
                                        <FaLocationArrow height={17} width={17} className="mr-2" />
                                        Get Direction
                                    </p>
                                </div></div>

                        </div>
                        <div className='text-justify show-marker-info-marker-caption mt-3 hidden md:block'>
                            {selectedMarker.caption}
                        </div>

                    </div>
                    <div className="w-1/4 p-1 hidden md:block">
                        <p
                            onClick={() => { handleGetDirection(selectedMarker.direction_url) }}
                            className="w-full flex m-1 p-1 items-center rounded justify-center custom-button"
                        >
                            <FaLocationArrow height={17} width={17} className="mr-2" />
                            Get Direction
                        </p>


                    </div>

                </div>

            </div>
            <div className={`w-full md:hidden  ${(selectedMarker.thumbnail || (selectedMarker.caption&&selectedMarker.caption!==""))&&'pb-2'} border-b-2`}>
                {selectedMarker.thumbnail && returnCorrectImageUrl(selectedMarker.thumbnail, "small") && <div className="w-full md:hidden">
                    <img src={returnCorrectImageUrl(selectedMarker.thumbnail, "small")} className={`w-full h-auto object-cover ${!returnCorrectImageUrl(selectedMarker.thumbnail, "small") && 'bg-gray-200 p-16'}`}></img>
                </div>}
                <div className={`text-justify show-marker-info-marker-caption ${(selectedMarker.caption&&selectedMarker.caption!=="")&&'mt-3 ml-2 mr-2'}  md:hidden `}>
                    {selectedMarker.caption}
                </div>
            </div>

            <div className="w-full flex pb-2 mt-2 md:pb-4 md:mt-4 ">
            <div className="w-full flex pb-4 mt-4">
                <div className='flex justify-between w-full'>
                    <p
                        className="m-1 flex p-1 show-marker-info-marker-photos-reels-heading"
                    >
                        Top Reels and Posts
                    </p>
                    {userType==="influencer" && <div  className='p-1 md:w-1/4 mr-2 md:mr-0'> 
                        <p
                            onClick={handleOpenAddPost}
                            className="w-full m-1 flex p-1 items-center rounded justify-center custom-button"
                        >
                            <FaPlus height={17} width={17} className="mr-2" />
                            Add a Post
                        </p>
                    </div>}
                </div>
            </div>
            </div>

            <AddPost
                isOpen={openAddPost}
                onCancel={handleCloseAddPost}
                cityId={selectedMarker.cityId}
                layerId={selectedMarker.layerId}
                markerId={selectedMarker._id}
                onSuccessAdd={handleSuccessfulAdd}
            />

{!openAddPost&&<ShowPost markerId={selectedMarker._id}/>}


        </div>
    )
}