import axiosInstance from "../utils/AxiosInterceptor";

const SavedMapService = {
  // Create a new saved map
  createSavedMap: async (savedMapData) => {
    try {
      const response = await axiosInstance.post('/saved-maps', savedMapData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  // Get a saved map by ID
  getSavedMapById: async (id) => {
    try {
      const response = await axiosInstance.get(`/saved-maps/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  // Update a saved map by ID
  updateSavedMapById: async (id, updatedData) => {
    try {
      const response = await axiosInstance.patch(`/saved-maps/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  // Delete a saved map by ID
  deleteSavedMapById: async (id) => {
    try {
      const response = await axiosInstance.delete(`/saved-maps/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  // Get saved maps by user ID
  getSavedMapsByUserId: async (userId) => {
    try {
      const response = await axiosInstance.get(`/saved-maps/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
};

export default SavedMapService;
