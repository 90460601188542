import Pica from 'pica';
async function resizeImage(imageFile, percentage) {
    // Ensure the percentage is between 0 and 100
    if (percentage < 0 || percentage > 100) {
        throw new Error('Percentage must be between 0 and 100');
    }

    // Create an offscreen canvas for resizing the image
    const offscreenCanvas = document.createElement('canvas');
    const ctx = offscreenCanvas.getContext('2d');

    // Create an image element
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    // Wait for the image to load
    await new Promise((resolve) => {
        img.onload = resolve;
    });

    // Calculate the new dimensions
    const width = img.width * (percentage / 100);
    const height = img.height * (percentage / 100);

    // Set the canvas size to the new dimensions
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;

    // Use Pica to resize the image
    const pica = new Pica();

    const resizedCanvas = await pica.resize(img, offscreenCanvas, {
        quality: 3,
        alpha: true
    });

    // Convert the resized canvas to a blob
    const blob = await new Promise(resolve => resizedCanvas.toBlob(resolve, 'image/png', 0.90));

    // Create a new File object from the blob
    const resizedFile = new File([blob], imageFile.name, {
        type: 'image/jpeg',
        lastModified: new Date().getTime()
    });

    return resizedFile;
}

export default resizeImage;