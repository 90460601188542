import React, { useEffect, useRef, useState, useContext } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as d3 from 'd3';
import { CityContext } from '../../Context/CityContext';
import returnCorrectImageUrl from '../../utils/returnCorrectImageUrl';



const EditableMapComponent = ({ city, currentCheckedChangeId }) => {
    const { addMarker, setAddMarker, currentMapLayerElement, actualMarkerPos, allMarkers, editMarker, setEditMarker, setSelectedMarker, setCurrentCheckedChangeId } = useContext(CityContext);
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const iconRatio = 54 / 1512;

    const currentPopUp = useRef({}).current;
    function addPopUp(map) {
        let marker = currentPopUp.marker
        if (!marker) {
            return
        }
        let popUpCount = document.querySelector(".leaflet-pane.leaflet-popup-pane").children.length
        if (popUpCount > 0) {
            let parent = document.querySelector(".leaflet-pane.leaflet-popup-pane")
            parent.removeChild(parent.firstChild)
        }
        let zoomScale = map.getZoom()
        let { x, y } = map.latLngToContainerPoint(marker.getLatLng())
        let mapWidth = currentPopUp.width
        let content = currentPopUp.content
        const iconSize = Math.ceil(iconRatio * mapWidth * zoomScale);
        // Create the outer container with the specified classes
        var outerContainer = document.createElement('div');
        outerContainer.className = 'leaflet-popup leaflet-zoom-animated';

        // Create the content wrapper
        var contentWrapper = document.createElement('div');
        contentWrapper.className = 'leaflet-popup-content-wrapper';

        // Create the content div
        var contentDiv = document.createElement('div');
        contentDiv.className = 'leaflet-popup-content';
        contentDiv.style.whiteSpace = 'nowrap';
        contentDiv.textContent = content;
        contentWrapper.appendChild(contentDiv);

        // Nest the elements
        var popUpTipContainer = document.createElement("div")
        popUpTipContainer.className = "leaflet-popup-tip-container"

        var popUpTip = document.createElement("div")
        popUpTip.className = "leaflet-popup-tip"
        popUpTipContainer.appendChild(popUpTip)

        var closeButtonContainer = document.createElement('a');
        closeButtonContainer.className = "leaflet-popup-close-button"
        closeButtonContainer.role = "button"
        closeButtonContainer.ariaLabel = "Close popup"
        closeButtonContainer.href = "#close"
        closeButtonContainer.innerHTML = `<span aria-hidden="true">×</span>`
        closeButtonContainer.onclick = function () {
            document.querySelector(".leaflet-pane.leaflet-popup-pane").removeChild(outerContainer);
        };




        outerContainer.appendChild(contentWrapper);
        outerContainer.appendChild(popUpTipContainer)
        outerContainer.appendChild(closeButtonContainer)

        // Apply styles to avoid affecting the layout
        outerContainer.style.position = 'absolute';
        outerContainer.style.transform = `translate3d(${Math.floor(x)}px, ${Math.floor(y)}px, 0)`




        // Append to the body
        document.querySelector(".leaflet-pane.leaflet-popup-pane").appendChild(outerContainer);


        // Get the width
        var width = contentDiv.offsetWidth;
        let height = contentDiv.offsetWidth
        //outerContainer.style.bottom=`-${height-20}px`
        //outerContainer.style.bottom=`-${(iconSize/2)+(width/2)}px`

        // Remove the temporary container from the DOM
        //document.querySelector(".leaflet-pane.leaflet-popup-pane").removeChild(outerContainer);

        // Return the width
        return width;
    }


    const handleCityDataChange = (changedData, width, height) => {

        if (changedData.object == "marker" && changedData.action == "add") {
            addMarkerData(currentMapLayerElement[changedData.layerId].data, changedData, width, height, iconRatio, currentMapLayerElement["map"], changedData.layerId)
            updateMarkerPositions(currentMapLayerElement["map"], width, iconRatio)
            delete city.changedData
        }
        else if (changedData.object == "marker" && changedData.action == "update") {
            currentMapLayerElement[changedData.layerId].data.removeLayer(currentMapLayerElement[changedData._id].data)
            addMarkerData(currentMapLayerElement[changedData.layerId].data, changedData, width, height, iconRatio, currentMapLayerElement["map"], changedData.layerId)
            updateMarkerPositions(currentMapLayerElement["map"], width, iconRatio)
            delete city.changedData
        }
        else if (changedData.object == "marker" && changedData.action == "delete") {
            currentMapLayerElement[changedData.layerId].data.removeLayer(currentMapLayerElement[changedData._id].data)
            delete currentMapLayerElement[changedData._id]
        } else if (changedData.object == "layer" && changedData.action == "add") {
            let newLayer = L.layerGroup().addTo(currentMapLayerElement["map"]);
            currentMapLayerElement[changedData._id] = { display: true, type: "layer", data: newLayer };
        }
        // else if(changedData.object=="layer" && changedData.action=="update"){
        //     currentMapLayerElement[changedData.layerId].data.removeLayer(currentMapLayerElement[changedData._id].data)
        //     addMarkerData(currentMapLayerElement[changedData.layerId].data,changedData,width,height,iconRatio)
        // }
        else if (changedData.object == "layer" && changedData.action == "delete") {
            currentMapLayerElement["map"].removeLayer(currentMapLayerElement[changedData._id].data)
            delete currentMapLayerElement[changedData._id]
        }

    }

    const calculateDimensions = () => {
        mapContainerRef.current.style.width = 'calc(100% - 50px)';
        const containerWidth = mapContainerRef.current.clientWidth;
        const maxWidth = 1920; // Maximum width
        const maxHeight = 1080;
        let width = containerWidth;
        let height = (width * maxHeight) / maxWidth;

        if (height > maxHeight) {
            height = maxHeight;
            width = (height * maxWidth) / maxHeight;
        }

        return { width, height };
    };

    const addMarkerData = (newLayer, markerData, width, height, iconRatio, map, layerId) => {

        const markerName = `${markerData.name}`;
        const latLng = [markerData.coordinates.latitude * height, markerData.coordinates.longitude * width];
        actualMarkerPos[markerData._id] = { x: markerData.coordinates.latitude * height, y: markerData.coordinates.longitude * width };

        const marker = L.marker(latLng, { draggable: false })
            .bindPopup(`${markerName}`);

        const zoomScale = 1;
        const iconSize = Math.ceil(iconRatio * width * zoomScale);
        marker.setIcon(new L.Icon({
            iconUrl: returnCorrectImageUrl(markerData.marker_icon, "small"),
            iconSize: [iconSize, iconSize],
            iconAnchor: [iconSize / 2, iconSize / 2],
            popupAnchor: [0, -iconSize / 2]
        }));
        marker.on('click', () => {
            let updatedMarkerData = { ...markerData }
            updatedMarkerData.cityId = city._id
            updatedMarkerData.layerId = layerId
            setSelectedMarker(updatedMarkerData)
            currentPopUp.marker = marker
            currentPopUp.width = width
            currentPopUp.content = markerName
            //addPopUp(map)
        })
        marker.addTo(newLayer);
        currentMapLayerElement[markerData._id] = { display: true, type: "marker", data: marker, parent: newLayer };
        allMarkers.push({ marker, _id: markerData._id });
    };

    const updateMarkerPositions = (map, width, iconRatio) => {
        let markerIdandIndex = {}
        let currentMarkerNodes = [];
        //addPopUp(map)
        let i = 0
        let copyOfallMarkers = [...allMarkers]

        copyOfallMarkers.forEach(function (marker) {
            let physicalCoordinate = map.latLngToLayerPoint(L.latLng(actualMarkerPos[marker._id].x, actualMarkerPos[marker._id].y));
            let node = { x: physicalCoordinate.x, y: physicalCoordinate.y, r: 25 };
            if (markerIdandIndex[marker._id]) {
                currentMarkerNodes[markerIdandIndex[marker._id]] = node
                allMarkers[markerIdandIndex[marker._id]] = marker
                allMarkers.splice(i, 1);
                //can i here remove the markerIdandIndex[marker._id] th element from allMarkers
            } else {
                currentMarkerNodes.push(node);
                markerIdandIndex[marker._id] = i++
            }

        });

        const simulation = d3.forceSimulation()
            .force("collide", d3.forceCollide().radius(d => d.r + 1).iterations(0.1));

        simulation.nodes(currentMarkerNodes).tick();

        allMarkers.forEach((markerData, i) => {
            let marker = markerData.marker
            const zoomScale = 1 + (0.5 * map.getZoom());
            const iconSize = Math.ceil(iconRatio * width * zoomScale);
            marker.setIcon(new L.Icon({
                iconUrl: marker.options.icon.options.iconUrl,
                iconSize: [iconSize, iconSize],
                iconAnchor: [iconSize / 2, iconSize / 2],
                popupAnchor: [0, -iconSize / 2]
            }));
            let newCoordinate = currentMarkerNodes[i];
            marker.setLatLng(map.layerPointToLatLng(L.point(newCoordinate.x, newCoordinate.y)));
        });
    };
    // const updateMarkerPositions = (map, width, iconRatio) => {
    //     let currentMarkerNodes = [];
    //     let keyIndexMap={}


    //     // Convert actualMarkerPos object to an array
    //     const actualMarkerPosArray = Object.entries(actualMarkerPos);
    //     //let radius=Math.ceil(iconRatio * width * (1 + (0.5 * map.getZoom())))
    //     let radius=25


    //     actualMarkerPosArray.forEach(function ([key,marker],i) {
    //         let physicalCoordinate = map.latLngToLayerPoint(L.latLng(marker.x, marker.y));
    //         let node = { x: physicalCoordinate.x, y: physicalCoordinate.y, r: radius };
    //         currentMarkerNodes.push(node);
    //         keyIndexMap[key]=i
    //     });

    //     const simulation = d3.forceSimulation()
    //         .force("collide", d3.forceCollide().radius(d => d.r + 1).iterations(0.1));

    //     simulation.nodes(currentMarkerNodes).tick();

    //     // Convert allMarkers object to an array of entries [key, value]
    //     const allMarkersEntries = allMarkers;

    //     allMarkersEntries.forEach(([key, marker], i) => {
    //         const zoomScale = 1 + (0.5 * map.getZoom());
    //         const iconSize = Math.ceil(iconRatio * width * zoomScale);
    //         marker.setIcon(new L.Icon({
    //             iconUrl: marker.options.icon.options.iconUrl,
    //             iconSize: [iconSize, iconSize],
    //             iconAnchor: [iconSize / 2, iconSize / 2],
    //             popupAnchor: [0, -iconSize / 2]
    //         }));
    //         let newCoordinate = currentMarkerNodes[keyIndexMap[key]];
    //         marker.setLatLng(map.layerPointToLatLng(L.point(newCoordinate.x, newCoordinate.y)));
    //     });
    // };
    const initializeMap = (width, height) => {
        const map = L.map(mapContainerRef.current, {
            crs: L.CRS.Simple,
            minZoom: 0,
            maxZoom: 4,
            maxBounds: L.latLngBounds([[0, 0], [height, width]])
        }).setView([0, 0], 0);

        L.imageOverlay(city.baseMap.imageUrl, [[0, 0], [height, width]]).addTo(map);

        const overlays = {};
        for (let layer of city.layers) {
            let newLayer = L.layerGroup().addTo(map);
            overlays[layer.name] = newLayer;
            for (let marker of layer.markers) {
                addMarkerData(newLayer, marker, width, height, iconRatio, map, layer._id); // Assuming iconRatio is 0.05, adjust as needed
            }
            currentMapLayerElement[layer._id] = { display: true, type: "layer", data: newLayer };
        }

        map.on('zoomend', () => updateMarkerPositions(map, width, iconRatio)); // Assuming iconRatio is 0.05, adjust as needed
        updateMarkerPositions(map, width, iconRatio); // Assuming iconRatio is 0.05, adjust as needed
        currentMapLayerElement["map"] = map;

        mapRef.current = map;
    };

    useEffect(() => {

        const { width, height } = calculateDimensions();


        if (currentMapLayerElement && currentMapLayerElement.cityId == city._id) {
            if (!mapRef.current) {
                mapContainerRef.current.appendChild(currentMapLayerElement["map"].getContainer())
                currentMapLayerElement["map"].getContainer().style.marginLeft = '0px'
                currentMapLayerElement["map"].getContainer().style.width = '100%'
                currentMapLayerElement["map"].getContainer().style.cursor = addMarker && addMarker.status && !addMarker.selected ? 'crosshair' : 'grab'
            }
            if (city.changedData)
                handleCityDataChange(city.changedData, width, height)

            console.log("Returning")
            return
        }
        setDimensions({ width, height });
        if (mapContainerRef.current) {
            mapContainerRef.current.style.width = `${width}px`;
            mapContainerRef.current.style.height = `${height}px`;
        }
        if (mapRef.current) {
            mapRef.current.remove();
            mapRef.current = null;
        }
        currentMapLayerElement.cityId = city._id
        console.log("Forward")
        initializeMap(width, height);

    }, [city]);
    useEffect(() => {
        function onMapClick(e) {
            let { width, height } = calculateDimensions()
            var coord = e.latlng;
            var lat = coord.lat;
            var lng = coord.lng;
            let data = { ...addMarker }
            data.lat = lat / height;
            data.lng = lng / width;
            data.selected = true
            data.currLat = lat;
            data.currLng = lng;
            setAddMarker(data)

        }
        if (addMarker && addMarker.status && !addMarker.selected) {
            if (currentMapLayerElement["map"]) {
                currentMapLayerElement["map"].getContainer().style.cursor = addMarker && addMarker.status && !addMarker.selected ? 'crosshair' : 'grab';
            }
            currentMapLayerElement["map"].once('click', onMapClick)
        }
    }, [addMarker])

    useEffect(() => {
        if (!editMarker) {
            setEditMarker(calculateDimensions())
            return
        }
        function onMapClick(e) {
            let { width, height } = calculateDimensions()
            var coord = e.latlng;
            var lat = coord.lat;
            var lng = coord.lng;
            let data = { ...editMarker }
            data.data.coordinates.latitude = lat / height;
            data.data.coordinates.longitude = lng / width;
            data.selected = true
            setEditMarker(data)

        }
        if (editMarker && editMarker.status && !editMarker.selected) {
            if (currentMapLayerElement["map"]) {
                currentMapLayerElement["map"].getContainer().style.cursor = editMarker && editMarker.status && !editMarker.selected ? 'crosshair' : 'grab';
            }
            currentMapLayerElement["map"].once('click', onMapClick)
        }
    }, [editMarker])

    useEffect(() => {
        if (!currentCheckedChangeId) return;

        const element = currentMapLayerElement[currentCheckedChangeId._id];

        if (element) {
            element.display = !element.display;
            const map = currentMapLayerElement["map"];
            if (element.display && element.type == "marker") {
                element.parent.addLayer(element.data);
            }
            else if (element.display && element.type == "layer") {
                map.addLayer(element.data);
            }
            else if (element.type == "marker") {
                element.parent.removeLayer(element.data);
            }
            else {
                map.removeLayer(element.data);
            }
        }
        setCurrentCheckedChangeId(null)
    }, [currentCheckedChangeId]);

    useEffect(() => {
        const handleResize = () => {
            // if (mapRef.current) {
            //     const { width, height } = calculateDimensions();
            //     setDimensions({ width, height });
            //     mapContainerRef.current.style.width = `${width}px`;
            //     mapContainerRef.current.style.height = `${height}px`;
            //     mapRef.current.invalidateSize();
            // }
            const { width, height } = calculateDimensions();
            setDimensions({ width, height });
            if (mapContainerRef.current) {
                mapContainerRef.current.style.width = `${width}px`;
                mapContainerRef.current.style.height = `${height}px`;
            }
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
            for (let key in actualMarkerPos) {
                if (actualMarkerPos.hasOwnProperty(key)) {
                    delete actualMarkerPos[key];
                }
            }
            allMarkers.length = 0
            currentMapLayerElement.cityId = city._id
            initializeMap(width, height);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    return (
        <div
            id="map"
            ref={mapContainerRef}
            style={{
                maxWidth: '1920px',
                maxHeight: '1080px',
                marginLeft: '24px',
                width: '100px',
                cursor: addMarker && addMarker.status && !addMarker.selected ? 'crosshair' : 'grab'
            }}
        ></div>
    );
};

export default EditableMapComponent;
