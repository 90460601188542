import axiosInstance from "../utils/AxiosInterceptor";

const imageUploadService = {
  initiateUpload: async (fileSize, fileName, fileType) => {
    try {
      const response = await axiosInstance.post('/image/initiate', {
        fileSize,
        fileName,
        fileType,
      });
      return response.data;
    } catch (error) {
      console.error('Error initiating upload:', error);
      throw error;
    }
  },

  uploadChunk: async (fileId, partNumber, chunk) => {
    try {
      const formData = new FormData();
      formData.append('image', chunk);

      const response = await axiosInstance.post(`/image/uploadChunk/${fileId}/${partNumber}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading chunk:', error);
      throw error;
    }
  },

  completeUpload: async (fileId) => {
    try {
      const response = await axiosInstance.post(`/image/completeUpload/${fileId}`);
      return response.data;
    } catch (error) {
      console.error('Error completing upload:', error);
      throw error;
    }
  },
};

export default imageUploadService;
