import { useState } from "react";
import ImageOrVideoOrFileInput from "../../../../components/ImageOrVideoOrFileInput";
import userService from "../../../services/UserService";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from "../SavedMap/SuccessModal";

export default function ProfileForm({ isOpen, data,setData,setProfileImageUrl }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        console.log(`${year}-${month}-${day}`)
        return `${year}-${month}-${day}`;
    };
    let formField = {
        username: data.username || '',
        email: data.email,
        mobile: data.mobile || '',
        dob: data.dob?formatDate(data.dob): '',
        gender: data.gender || '',
        country: data.country || '',
        name: data.name || '',
        profileImageUrl: data.profileImageUrl || {},
        instagramUrl: data.instagramUrl || ''
    }
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(formField);
    const [imageError, setImageError] = useState({
        profileImageUrl: { error: false, selected:  data.profileImageUrl?.large?.imageUrl?true:false, uploaded: data.profileImageUrl?.large?.imageUrl?true:false },
      });

    if (!isOpen) {
        return null;
    }

    const handleSuccessClose=()=>{
        setSuccess(false)
    }
    const handleProfileImageChange = (imageData, width, height) => {
        //console.log(imageData)
        let data = { ...imageError };
        if (!imageData) {
          data.profileImageUrl.selected = false;
          data.profileImageUrl.uploaded = false;
        }
        else if (imageData.selected) {
          data.profileImageUrl.selected = true;
        }
        else {
          data.profileImageUrl.uploaded = true;
        }
    
        setImageError(data);
        
        setFormData({
          ...formData,
          profileImageUrl: imageData,
        });
      };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(imageError.profileImageUrl.selected && !imageError.profileImageUrl.uploaded){
                let tempError={...imageError}
                tempError.profileImageUrl.error=true
                setImageError(tempError)
                return
            }
            setLoading(true)
            let response=await userService.updateUser(formData)
            setData(response)
            setProfileImageUrl(formData.profileImageUrl)

            setSuccess(true);
            setLoading(false);
            
        } catch (error) {
            console.error('There was an error creating the user!', error);
            setLoading(false);
            toast.error("Some error Occurred, Please Try Again");
        }
    };

    return (
        <form onSubmit={handleSubmit} className='flex-1 '>
            <ToastContainer />
            <div className='md:w-3/4 md:flex md:flex-wrap'>
            <div className='w-full'>
            <label htmlFor="name">Profile Picture</label>
            <div className={`w-48 ${!(formData.profileImageUrl?.large?.imageUrl) && 'h-32'}`}>
            <ImageOrVideoOrFileInput  onChange={handleProfileImageChange} imageUrl={formData.profileImageUrl && formData.profileImageUrl.large && formData.profileImageUrl.large.imageUrl} error={imageError.profileImageUrl.error} uploaded={imageError.profileImageUrl.uploaded} isMarker={false} height={'h-full'}/>
            </div>
            </div>
                <div className='md:w-1/2'>
                    <label htmlFor="name">Name</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        placeholder="Enter your name"
                        onChange={handleChange}
                        value={formData.name}
                    />
                </div>
                <div className='md:w-1/2 md:pl-4'>
                    <label htmlFor="username">Username</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        required
                        minLength="5"
                        placeholder="Enter your username"
                        onChange={handleChange}
                        value={formData.username}
                    />
                </div>
                <div className='md:w-1/2'>
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        disabled
                        placeholder="Enter your email"
                        onChange={handleChange}
                        value={formData.email}
                    />
                </div>
                <div className='md:w-1/2 md:pl-4'>
                    <label htmlFor="mobile">Mobile</label>
                    <input
                        id="mobile"
                        name="mobile"
                        type="tel"
                        pattern="[0-9]{10}"
                        required
                        placeholder="Enter your mobile number"
                        onChange={handleChange}
                        value={formData.mobile}
                    />
                </div>
                <div className='md:w-1/2'>
                    <label htmlFor="dob">Date of Birth</label>
                    <input
                        id="dob"
                        name="dob"
                        type="date"
                        onChange={handleChange}
                        value={formData.dob}
                    />
                </div>
                <div className='md:w-1/2 md:pl-4'>
                    <label htmlFor="gender">Gender</label>
                    <select
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                        value={formData.gender}
                    >
                        <option value="">Select your gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className='md:w-1/2'>
                    <label htmlFor="country">Country</label>
                    <input
                        id="country"
                        name="country"
                        type="text"
                        required
                        placeholder="Enter your country"
                        onChange={handleChange}
                        value={formData.country}
                    />
                </div>
                <div className='md:w-1/2 md:pl-4'>
                    <label htmlFor="instagramUrl">Instagram URL</label>
                    <input
                        id="instagramUrl"
                        name="instagramUrl"
                        type="url"
                        placeholder="Enter your Instagram URL"
                        onChange={handleChange}
                        value={formData.instagramUrl}
                    />
                </div>
            </div>
            <div className='text-right md:w-3/4 mt-2 md:mt-4'>
                <button type="submit" className='text-white font-bold w-full md:w-32 bg-[#2B688E] p-2 text-base rounded-3xl'>Save</button>
            </div>

            <SuccessModal message={`Profile Updated Successfully`} onClose={handleSuccessClose} isOpen={success}/>
      {loading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
        </form>
    )
}
