import React from 'react';
import { FaCity } from 'react-icons/fa'; // Importing a city icon from react-icons/fa

const NoCitiesAvailable = () => {
  return (
    <div className="flex items-center justify-center h-96 w-full">
      <div className="bg-white p-8 rounded-lg shadow-md text-center flex flex-col items-center">
        <FaCity className="text-6xl text-gray-400 mb-4" />
        <h1 className="text-2xl font-semibold text-gray-700">No Cities Available</h1>
        <p className="text-gray-500">Currently, there are no cities to display.</p>
      </div>
    </div>
  );
};

export default NoCitiesAvailable;
