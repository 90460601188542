import React, { useState, useEffect } from 'react';
import cityService from '../../services/CityService';
import ImageInput from '../../components/ImageInput';
import SuccessModal from '../../components/Modal/SuccessModal'; // Import the modal component


const EditCityInfo = ({ city, setEditCity }) => {
  const [cityData, setCityData] = useState({
    name: '',
    subtitle: '',
    baseMap: {
      imageUrl: null,
      dimension: {
        height: 0,
        width: 0
      }
    },
    isDeleted: false,
    layers: []
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for controlling the modal display

  useEffect(() => {
    // Initialize cityData with the city prop
    setCityData({
      name: city.name,
      subtitle: city.subtitle,
      baseMap: city.baseMap,
      isDeleted: city.isDeleted,
      layers: city.layers
    });
  }, [city]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCityData({
      ...cityData,
      [name]: value
    });
  };

  const handleImageChange = (baseMap, width, height) => {
    setCityData({
      ...cityData,
      baseMap: {
        fileId: baseMap ? baseMap.fileId : undefined,
        imageUrl: baseMap ? baseMap.imageUrl : undefined,
        dimension: {
          width:width>0?width:undefined,
          height:height>0?height:undefined
        }
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: cityData.name,
        subtitle: cityData.subtitle,
        baseMap: cityData.baseMap,
      };

      // Make the JSON request
      const response = await cityService.updateCity(city._id, payload);
      ////console.log('City updated successfully:', response);
      setShowSuccessModal(true); // Show the success modal upon successful update
    } catch (error) {
      console.error('Error updating city:', error);
      // Optionally: handle error (e.g., show an error message)
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  return (
    <div className="max-w-screen-md mt-2 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl mb-4">Edit City</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-semibold mb-1">Name:</label>
          <input
            type="text"
            name="name"
            value={cityData.name}
            onChange={handleChange}
            className="border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-semibold mb-1">Subtitle:</label>
          <input
            type="text"
            name="subtitle"
            value={cityData.subtitle}
            onChange={handleChange}
            className="border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <ImageInput
          onChange={handleImageChange}
          imageUrl={cityData.baseMap.imageUrl}
          width={cityData.baseMap.dimension.width}
          height={cityData.baseMap.dimension.height}
        />
        <div className="mt-6">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update City
          </button>
          <button
            type="button"
            onClick={() => { setEditCity(false); }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-4"
          >
            Cancel
          </button>
        </div>
      </form>
      {showSuccessModal && (
        <SuccessModal
          message="City updated successfully!"
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default EditCityInfo;
