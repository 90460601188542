
import './RightBar.css'
import SavedMap from "./SavedMap/SavedMap"
import Feedback from "./FeedBack"
import Profile from "./Profile/Profile"

import { FaBars } from 'react-icons/fa6' 
import userLogo from './user.svg' 
import { useEffect, useState } from 'react'
import LogoutModal from './Profile/LogOutModal'
import { logout } from '../../../services/AuthService'
import ChangePasswordModal from './Profile/ChangePasswordModal'
import SuccessModal from './SavedMap/SuccessModal'
import ShowPost from '../InfluencerPost/ShowPost'

const RightBar = ({active,setActive,setIsDrawerOpen,isLogOutOpen,setIsLogOutOpen,isChangePasswordOpen,setIsChangePasswordOpen}) => {
    const [profileImageUrl,setProfileImageUrl]=useState(null)
    const [email,setEmail]=useState('')
    useEffect(()=>{
        let tempProfileImageUrl= JSON.parse(localStorage.getItem('profileImageUrl'))
        if(tempProfileImageUrl?.small?.imageUrl){
            setProfileImageUrl(tempProfileImageUrl)
        }
    },[])
   


    const [isLogOutLoading,setIsLogOutLoading]=useState(false)
    const [success,setSuccess]=useState(false)


    const handleSuccessClose=()=>{
        setSuccess(false)
        setIsChangePasswordOpen(false)

    }

    const handleLogOut =async ()=>{
        setIsLogOutLoading(true)
        logout();
    }

    const handleLogOutClose=()=>{
        setIsLogOutOpen(false)
    }

    const handleChangePasswordClose=()=>{
        setIsChangePasswordOpen(false)
    }

    const handleNavChange=(elem)=>{
        setActive({
            
            location:false,profile:false,feedback:false,
            [elem]:true
        })
        setIsDrawerOpen(false)
    }
   
    return (
        <div className='h-full flex flex-col'>
            <div className="flex w-full bg-white h-24 p-2 md:p-5 font-bold text-lg md:text-2xl items-center">
            <div className='md:hidden'>
                <FaBars size={40} className='text-[#343C6A] p-2' onClick={()=>{setIsDrawerOpen(true)}}/>
            </div>
            <div className='flex flex-1 text-[#343C6A] justify-center md:justify-start'>
                {active.location && 'My saved maps'}
                {active.profile && 'Maps'}
                {active.feedback && 'FeedBack'}
                {active.posts && 'All Posts'}
            </div>
            <div onClick={()=>{handleNavChange('profile')}}>
                <img src={profileImageUrl?.small?.imageUrl || userLogo} className='rounded-full h-10 w-10 bg-[#F5F7FA] p-2'/>
            </div>
            </div>
            <div className={`flex-1 w-full overflow-y-auto ${!active.posts && 'p-2'} md:p-4`}>
           <SavedMap isOpen={active.location}/>
           <Profile isOpen={active.profile} setProfileImageUrl={setProfileImageUrl} setEmail={setEmail}/>
           <Feedback isOpen={active.feedback}/>
           {active.posts &&<ShowPost isOpen={active.posts}/>}
           </div>
           <LogoutModal
             isOpen={isLogOutOpen}
             onClose={handleLogOutClose}
             onLogout={handleLogOut}
             loading={isLogOutLoading}
             />
            {isChangePasswordOpen &&<ChangePasswordModal onClose={handleChangePasswordClose} setSuccess={setSuccess} email={email}/>}
            <SuccessModal isOpen={success} message={"Password is changed successfully"} onClose={handleSuccessClose}/>
        </div>
    )
}

export default RightBar