import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Profile.css'
import ProfileForm from './ProfileForm';
import EmailVerificationForm from './EmailVerificationForm';
import userService from '../../../services/UserService';
import { FaExclamationCircle } from 'react-icons/fa';

const Profile = ({ isOpen,setProfileImageUrl,setEmail }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
       userService.getUserData()
            .then((data) => {
                setData(data);
                setEmail(data.email)
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }, []);



    const [isProfileTab, setIsProfileTab] = useState(true)
    const [isVerificationTab, setIsVerificationTab] = useState(false)
    if (!isOpen) return null;


    const handleProfileClick = () => {
        setIsProfileTab(true)
        setIsVerificationTab(false)
    }
    const handleVerificationClick = () => {
        setIsVerificationTab(true)
        setIsProfileTab(false)
    }

    return (
        <div className={`w-full ${isVerificationTab&& 'h-full'}`}>
            {loading && (
                <div className="y">
                    <div className="animate-spin rounded-full h-16 w-16 md:h-32 md:w-32 border-t-4 border-b-4 border-blue-500"></div>
                </div>
            )}
            {error && (
                <div className="flex flex-col items-center text-red-500">
                    <FaExclamationCircle className="h-16 w-16 mb-4 mt-4 md:mb-8 md:mt-8" />
                    <p>{error}</p>
                </div>
            )}
            {data && <div className={`bg-white p-4 md:pl-8 md:pr-8 md:pt-8 md:pb-4 rounded-3xl ${isVerificationTab && 'h-full'} flex flex-col profile`}>
                <div className='flex m-2 md:m-4 border-b '>
                    <p className={`font-base  text-[#718EBF] cursor-pointer ${isProfileTab && 'border-b-2 border-[#718EBF] font-bold'} py-2 px-4 `} onClick={handleProfileClick}>Edit Profile</p>
                    <p className={`font-base  text-[#718EBF] cursor-pointer ${isVerificationTab && 'border-b-2 border-[#718EBF] font-bold'} py-2 px-4 `} onClick={handleVerificationClick}>Profile Verification</p>
                </div>

                <ProfileForm isOpen={isProfileTab} data={data} setProfileImageUrl={setProfileImageUrl} setData={setData}/>
                <EmailVerificationForm isOpen={isVerificationTab} data={data}/>

            </div>}
        </div>
    );
};

export default Profile;
