import React, { useState, useRef, useEffect } from 'react';
import { BsUpload, BsTrash, BsCloudUpload, BsCheckCircle, BsExclamationCircle } from 'react-icons/bs';
import uploadImage from '../utils/UploadImage';
import uploadVideo from '../utils/UploadVideo';

const ImageOrVideoOrFileInput = ({ onChange, imageUrl, error, uploaded, isMarker, allowVideo, height, isVideo }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const inputRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null)
  const [uploadedPercentage,setUploadedpercentage]=useState(0)

  console.log("isVideo", isVideo)

  useEffect(() => {
    if (uploaded) {
      setUploadStatus('success');
    }
  }, [uploaded]);

  useEffect(() => {
    if (!isVideo) {
      setVideoUrl(null)
      return
    };
    setVideoUrl(imageUrl)
  }, [isVideo])

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (file.type.startsWith('image/')) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            setFile(file);
            setUploadStatus(null);
            onChange({ selected: true,type:'image', large: { imageUrl: image.src } });
            setUploadedpercentage(0)
          };
        } else if (allowVideo && file.type.startsWith('video/')) {
          const videoUrl = URL.createObjectURL(file);
          setFile(file);
          setUploadStatus(null);
          setVideoUrl(videoUrl)
          onChange({ selected: true, type: 'video', large: { imageUrl: videoUrl } });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    onChange(null, 0, 0); // Reset dimensions when file is removed
    inputRef.current.value = ''; // Reset the input field to allow selecting the same file again
    setUploadStatus(null); // Reset upload status when file is removed
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    setUploadStatus(null); // Reset upload status when new upload starts
    try {
      let imageData = isVideo ? await uploadVideo(file,false,setUploadedpercentage) : await uploadImage(file,isMarker,setUploadedpercentage)
      
      if(isVideo){
        if(!imageData.large || !imageData.large.imageUrl){
          setUploadStatus('error');
          return
        }}
        else{
          if(!imageData.large || !imageData.large.imageUrl || !imageData.small || !imageData.small.imageUrl || !imageData.medium || !imageData.medium.imageUrl){
            setUploadStatus('error');
            return
        }
      }
      if(isVideo){
        imageData.type="video"
      }else{
         imageData.type="image"
      }

      onChange(imageData);

      setUploadStatus('success');
    } catch (error) {
      console.error('Error during upload:', error);
      setUploadStatus('error');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={`flex flex-col w-full ${!imageUrl && !videoUrl && height && 'h-full'} border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md p-4`}>
      <div className={`relative flex items-center justify-center bg-gray-100 rounded-md overflow-hidden ${!imageUrl && !videoUrl && height && 'h-full'}`} style={{ height: (imageUrl || videoUrl) ? 'auto' : (height?'100%' : '100px') }}>
        <input
          ref={inputRef}
          type="file"
          accept={allowVideo ? 'image/*,video/*' : 'image/*'}
          onChange={handleFileChange}
          className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
        />
        {(imageUrl || videoUrl) ? (
          <div className="relative w-full">
            {videoUrl ? (
              <video src={videoUrl} controls className="w-full" />
            ) : (

              <img src={imageUrl} alt="Selected" className="w-full" />
            )}
            {uploading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <BsUpload className="h-6 w-6 text-gray-400" />
            <span className="ml-2 text-gray-500">Upload</span>
          </div>
        )}
        {(imageUrl || videoUrl) && !uploading && (
          <button
            type="button"
            onClick={handleRemoveFile}
            className="absolute top-1 right-1 bg-red-500 rounded-full p-1 flex items-center justify-center"
          >
            <BsTrash className="h-6 w-6 text-white" />
          </button>
        )}
      </div>

      {(imageUrl || videoUrl) && (
        <div className="mx-auto mt-2 flex items-center">
          {uploadStatus === 'success' ? (
            <div className="flex items-center text-green-500">
              <BsCheckCircle className="mr-2" />
              <span>Upload successful</span>
            </div>
          ) : uploadStatus === 'error' ? (
            <div className="flex items-center text-red-500">
              <BsExclamationCircle className="mr-2" />
              <button
                type="button"
                onClick={handleUpload}
                className="ml-4 px-3 py-2 rounded-md text-white flex items-center justify-center bg-red-500 hover:bg-red-600"
              >
                Retry
              </button>
            </div>
          ) : (
            <div className="w-full">
              <button
                type="button"
                onClick={handleUpload}
                className={`w-full px-3 py-2 rounded-md text-white flex items-center justify-center ${uploading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                disabled={uploading}
              >
                {uploading ? (
                  <span>Uploading... </span>
                ) : (
                  <>
                    <BsCloudUpload className="mr-2" />
                    Upload
                  </>
                )}
              </button>
              {uploading && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2 relative">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${uploadedPercentage}%` }}
                  ></div>
                  <span className="absolute top-0 left-0 w-full text-center text-xs font-semibold">
                    {uploadedPercentage}%
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageOrVideoOrFileInput;
