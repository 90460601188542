const returnCorrectImageUrl=(obj,type)=>{
    
    if(!obj){
        return null
    }
    else if(obj.imageUrl){
        return obj.imageUrl
    }
    else if(obj[type]){
        return obj[type].imageUrl
    }
    else return null;
}

export default returnCorrectImageUrl