import React,{useContext} from 'react';
import { useParams } from 'react-router-dom';
import LeftBar from './EditCityLeftBar';
import RightBar from './EditCityRightBar';
import { CityContext } from '../../Context/CityContext';

const EditCity = () => {
    const { cityId } = useParams();
    const {addMarker,editMarker } = useContext(CityContext);

    return (

            <div className="flex h-screen">
                <div className={`w-1/3.5 bg-gray-200 overflow-auto ${((addMarker && addMarker.status) || (editMarker && editMarker.status)) && "pointer-events-none opacity-50"}`}> {/* 1/4 width */}
                    <LeftBar />
                </div>
                <div className="w-2.5/3.5 overflow-auto" id="edit-city-right-container"> {/* 3/4 width */}
                    <RightBar />
                </div>
            </div>

    );
};

export default EditCity;