import axiosInstance from "../utils/AxiosInterceptor";

export const createMarker = async (cityId, layerId, markerData) => {
  try {
    const response = await axiosInstance.post(`/marker/${cityId}/${layerId}`, markerData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getMarkers = async (cityId, layerId) => {
  try {
    const response = await axiosInstance.get(`/marker/${cityId}/${layerId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getMarkerById = async (cityId, layerId, markerId) => {
  try {
    const response = await axiosInstance.get(`/marker/${cityId}/${layerId}/${markerId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updateMarker = async (cityId, layerId, markerId, markerData) => {
  try {
    const response = await axiosInstance.put(`/marker/${cityId}/${layerId}/${markerId}`, markerData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const deleteMarker = async (cityId, layerId, markerId) => {
  try {
    const response = await axiosInstance.delete(`/marker/${cityId}/${layerId}/${markerId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
