import axiosInstance from "../utils/AxiosInterceptor";

const getToken = () => localStorage.getItem('token');

const createLayer = (cityId, layerData) => {
    return axiosInstance.post(`/layer/${cityId}`, layerData, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};

const getLayers = (cityId) => {
    return axiosInstance.get(`/layer/${cityId}`);
};

const getLayer = (cityId, layerId) => {
    return axiosInstance.get(`/layer/${cityId}/${layerId}`);
};

const updateLayer = (cityId, layerId, layerData) => {
    return axiosInstance.put(`/layer/${cityId}/${layerId}`, layerData, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};

const deleteLayer = (cityId, layerId) => {
    return axiosInstance.delete(`/layer/${cityId}/${layerId}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};

export default {
    createLayer,
    getLayers,
    getLayer,
    updateLayer,
    deleteLayer,
};
