import logo from './Riitii_logo.svg'
import './LeftBar.css'
import location from './location.svg'
import post from './post.svg'


import { FaPlus, FaRightToBracket, FaXmark } from 'react-icons/fa6'



export default function LeftBar({isDrawerOpen, setIsDrawerOpen,active,setActive,isLogOutOpen,setIsLogOutOpen}) {

    const handleLogOutOpen=()=>{
        setIsLogOutOpen(true)
        setIsDrawerOpen(false)
    }

   
   

    const handleNavChange=(elem)=>{
        setActive({
            
            maps:false,posts:false,influencer:false,
            [elem]:true
        })
        setIsDrawerOpen(false)
    }

   

    

    return (
        <div className={`flex flex-col ${isDrawerOpen&&'h-full'} bg-white md:h-screen border-gray-400 md:border-r font-bold text-base`}>
            <div className='w-full flex justify-center h-24 p-5'>
                <img src={logo}/>
            </div>
            <ul className='cursor-pointer'>
                <li className={`flex items-center ${!active.maps?'opacity-50':'active'}`} onClick={()=>{handleNavChange('maps')}}><img src={location} className={`${active.maps&&'active-svg'} h-12 p-3`}></img>Maps</li>
                <li  className={`flex items-center ${!active.posts?'opacity-50':'active'}`} onClick={()=>{handleNavChange('posts')}}><img src={post} className={`${active.posts&&'active-svg'} h-12 p-3`}></img>Posts</li>
                <li  className={`flex items-center  ${!active.influencer ?'opacity-50':'active'}` } onClick={()=>{handleNavChange('influencer')}}><div className='h-12 p-3'><FaPlus size={24} style={{color:`${active.influencer ?'#2B688E': '#B1B1B1'}`}}/></div>Add Influencer</li>

                <li  className={`flex items-center  opacity-50` } onClick={handleLogOutOpen}><div className='h-12 p-3'><FaRightToBracket size={24} style={{color:'#B1B1B1'}}/></div>Log Out</li>
            
                 
            </ul>
            <div className='absolute md:hidden p-2 top-2 right-2 opacity-50' onClick={()=>{setIsDrawerOpen(false)}}>
                <FaXmark size={32}/>
            </div>

           
        </div>
    )
}