import React from 'react';
import riitiiText from './../riitii_text.svg';
import riitiiLogo from './../riitii_logo.svg';
import LoadingButton from './LoadingButton';


const EmailNotFound = ({ setOtpSent, setFound }) => {
    const handleBack=()=>{
        setOtpSent(false)
        setFound(false)
    }
  return (
    <div className="max-w-md w-full bg-white p-4 md:p-8">
      <div className='flex justify-center m-2 md:m-4'>
        <img src={riitiiText} alt="Riitii Text" />
      </div>
      <div className='rounded-lg p-4' style={{boxShadow:'0 0 10px 3px #b2b3b8'}}>
        <div className='flex justify-between items-center pt-2 pb-2 mb-2'>
          <p className='text-2xl font-bold ml-2'>Find Your Account</p>
          <img src={riitiiLogo} alt="Riitii Logo" />
        </div>
        <div className="rounded-md shadow-sm space-y-4 ml-2 text-sm text-gray-400">
          The Email Id is either Incorrect or the account does not exist
        </div>
        <div onClick={handleBack}>
        <LoadingButton loading={false} text={{main:"Go Back",load:"Finding"}}/>
        </div>
        <div className='flex justify-center items-center text-black mt-2'>
         <span className='opacity-50 mr-2'>New To Riitii?</span><span className='text-[#07B464] text-lg cursor-pointer' onClick={()=>{window.location.href="/signup"}}>Sign Up</span>
        </div>
      </div>
    </div>
  );
};

export default EmailNotFound;
