// CopyTextButton.js
import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';

const CopyTextButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <button className='text-gray-400 md:ml-2' onClick={copyToClipboard} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <FaCopy />
      {isCopied ? 'Copied!' : 'Copy Password'}
    </button>
  );
};

export default CopyTextButton;
