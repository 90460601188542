import React, { useState, useEffect } from 'react';
import LeftBar from "./LeftBar";
import RightBar from "./RightBar";
import cityService from "../../services/CityService";
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
    const [addCity, setAddCity] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [cities, setCity] = useState(null);
    const [currentCity, setCurrentCity] = useState(null);
    const [selectedMarker,setSelectedMarker] = useState(null)

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const cities = await cityService.getAllCities();
                setCity(cities);
            } catch (err) {
                // handle error
            }
        };

        fetchCities();
    }, []);
    const navigate = useNavigate()
    let userType=localStorage.getItem("userType")
    if(userType!=="admin"){
        navigate("/")
    }

    return (
        <div className="flex h-screen">
            <div className={`w-1/4 bg-gray-200 ${addCity || editCity ? 'pointer-events-none opacity-50' : ''}`}> {/* 1/4 width */}
                <LeftBar 
                    addCity={addCity} 
                    setAddCity={setAddCity} 
                    setCurrentCity={setCurrentCity} 
                    cities={cities} 
                    setSelectedMarker={setSelectedMarker}
                />
            </div>
            <div className="w-3/4 bg-white"> {/* 3/4 width */}
                <RightBar 
                    addCity={addCity} 
                    setAddCity={setAddCity} 
                    currentCity={currentCity} 
                    editCity={editCity} 
                    setEditCity={setEditCity}
                    setSelectedMarker={setSelectedMarker}
                    selectedMarker={selectedMarker}
                />
            </div>
        </div>
    );
}
