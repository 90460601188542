import React, { useState } from 'react';
import './ShowCity.css'

import 'leaflet/dist/leaflet.css';

import MapComponent from '../../components/Map/MapComponent';
import DeleteCityModal from './DeleteCityModal';
import SuccessModal from '../../components/Modal/SuccessModal';
import ShowMarkerInfo from '../Marker/ShowMarkerInfo';



const ShowCity = ({ currentCity, setEditCity,selectedMarker,setSelectedMarker }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const handleOpenModal = () => {
    const mapElement = document.getElementById("map");
    if (mapElement) {
      mapElement.style.zIndex = 0;
    }
    setIsModalOpen(true);
  };
  const handleSuccess = () => {
    setSuccessful(true)
    setIsModalOpen(false);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMessageCloseModal = () => {
    window.location.reload();
  }
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div>
          <p className="city-name text-xl font-bold">{currentCity.name}</p>
          <p className="sub-city-name text-xl font-bold">{currentCity.subtitle}</p>
        </div>

        <div>
          <button className="city-button mr-4" onClick={() => { setEditCity(true) }}>Edit City</button>
          <button className="city-button" onClick={handleOpenModal}>Delete City</button>
        </div>

      </div>

      <div className="w-full">
        <MapComponent city={currentCity} setSelectedMarker={setSelectedMarker}/>
      </div>
      {selectedMarker&&<ShowMarkerInfo selectedMarker={selectedMarker}/>}
      <DeleteCityModal
        cityId={currentCity._id}
        cityName={currentCity.name}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDeleteSuccess={handleSuccess}
      />
      {successful && (
        <SuccessModal
          message={`City Deleted Successfully`}
          onClose={handleMessageCloseModal}
        />
      )}
    </div>
  );
};

export default ShowCity;
