import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL?process.env.REACT_APP_BASE_URL:"https://map.riitii.com/api/",
});

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
  
   

    // Attempt to refresh token if response status is 401
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        // Call your refresh token endpoint with the refresh token in headers
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL?process.env.REACT_APP_BASE_URL:"https://map.riitii.com/api/"}users/resetAccessToken`,
          {
            headers: {
              'x-refresh-token': refreshToken,
            },
          }
        );
       

        // If refresh token succeeds, update tokens and retry original request
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        // Update the Authorization header with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${response.data.token}`;
        
        return axiosInstance(originalRequest);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403){
          if (!originalRequest.url.includes('/users/getUserData')) {
            window.location.href = '/';
          }
        }
        // Handle refresh token failure (e.g., logout user)
        ////console.log('Refresh token failed: ', error);
        // Perform logout or redirect to login page
        // Example: dispatch(logout());
        throw error;
      }
    }
    

    return Promise.reject(error);
  }
);

export default axiosInstance;