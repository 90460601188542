import { useState } from "react";
import ImageOrVideoOrFileInput from "../../components/ImageOrVideoOrFileInput";
import { FaTimes } from "react-icons/fa";
import SuccessModal from "../../components/Modal/SuccessModal";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import postService from "../../services/PostService";

export default function AddPost({ cityId, layerId, markerId, isOpen, onCancel, onSuccessAdd }) {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSuccessClose = () => {
        setFormData({
            username: '',
            instagramUrl: '',
            fileUrl: null,
            type:''
        })
        setSuccess(false)
        onSuccessAdd()
    };

    const [formData, setFormData] = useState({
        username: '',
        instagramUrl: '',
        fileUrl: null,
        type:''
    })

    const [error, setError] = useState({ status: false, selected: false, uploaded: false })
    //console.log(error)

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!error.selected || !error.uploaded){
            setError({...error,status:true})
            return
        }
        try {
            ////console.log(formData);
            setLoading(true);
            let data = { ...formData,cityId,layerId,markerId };
            const response = await postService.createPost(data)
            //console.log(response)
            setSuccess(true);
            setLoading(false);
            
          } catch (error) {
            setLoading(false);
            toast.error("Some error Occurred, Please Try Again");
          }

    };

    const handleUserameChange = (e) => {
        setFormData({
            ...formData,
            username: e.target.value
        })
    }

    const handleProfileUrlChange = (e) => {
        setFormData({
            ...formData,
            instagramUrl: e.target.value
        })
    }

    const handleFileChange = (imageData) => {
        if (!imageData) {
            setError({ status: false, selected: false, uploaded: false })
        }
        else if (imageData.selected) {
            setError({ status: false, selected: true, uploaded: false })
        }
        else {
            setError({ status: false, selected: true, uploaded: true })
        }
       
        setFormData({
            ...formData,
            type: imageData?imageData.type:'',
            fileUrl: imageData,
        })
    }

    const handleCancel=()=>{
        setFormData({
            username: '',
            instagramUrl: '',
            fileUrl: null,
            type:''
        })
        onCancel()
    }


    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 w-full h-full items-center justify-center flex">
            <ToastContainer />
            <div className="relative bg-white p-12 rounded-lg" style={{ width: '500px' }}>
                <p className="text-lg font-bold -ml-8 -mt-8">Add a post</p>
                <div className="w-full flex items-center justify-center">
                    <div className={`w-48 ${formData.fileUrl && formData.fileUrl.large ? '' : 'h-32'} rounded-lg`}>
                        <ImageOrVideoOrFileInput height={'h-full'} onChange={handleFileChange} imageUrl={formData.fileUrl && formData.fileUrl.large && formData.fileUrl.large.imageUrl} error={error.status} uploaded={error.uploaded} allowVideo={true} isVideo={formData.type=="video"} />
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                    <div className="w-full mb-4 ">
                        <label className="block text-sm font-medium text-gray-700">
                            Username
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            value={formData.username}
                            onChange={(e) => handleUserameChange(e)}
                            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
                            required
                        />
                    </div>
                    <div className="w-full mb-4">
                        <label className="block text-sm font-medium text-gray-700">Instagram Profile URL:
                            <span className="text-red-500">*</span>
                        </label>

                        <input
                            type="text"
                            value={formData.instagramUrl}
                            onChange={(e) => handleProfileUrlChange(e)}
                            className="w-full px-3 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <div className="flex justify-center w-full">
                        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-lg">Add Post</button>
                    </div>
                </form>
                <button
                    className="absolute top-3 right-3 text-black p-2 rounded-full"
                    onClick={handleCancel}
                >
                    <FaTimes />
                </button>
                {success &&<SuccessModal message={`Post Created Successfully`} onClose={handleSuccessClose}/>}
      {loading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
            </div>

        </div>
    )
}