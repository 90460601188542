import logo from './Riitii_logo.svg'
import './LeftBar.css'
import location from './location.svg'
import userLogo from './user.svg'
import feedbackLogo from './feedback.svg'
import post from './post.svg'

import { FaLock, FaRightToBracket, FaXmark } from 'react-icons/fa6'



export default function LeftBar({isDrawerOpen, setIsDrawerOpen,active,setActive,isLogOutOpen,setIsLogOutOpen,isChangePasswordOpen,setIsChangePasswordOpen,userType}) {

    const handleLogOutOpen=()=>{
        setIsLogOutOpen(true)
        setIsDrawerOpen(false)
    }

    const handleChangePasswordOpen=()=>{
        setIsChangePasswordOpen(true)
        setIsDrawerOpen(false)
    }
   

    const handleNavChange=(elem)=>{
        setActive({
            
            location:false,profile:false,feedback:false,posts:false,
            [elem]:true
        })
        setIsDrawerOpen(false)
    }

   

    

    return (
        <div className={`flex flex-col ${isDrawerOpen&&'h-full'} bg-white md:h-screen border-gray-400 md:border-r font-bold text-base`}>
            <div className='w-full flex justify-center h-24 p-5'>
                <img src={logo}/>
            </div>
            <ul className='cursor-pointer'>
                <li className={`flex items-center ${!active.location?'opacity-50':'active'}`} onClick={()=>{handleNavChange('location')}}><img src={location} className={`${active.location&&'active-svg'} h-12 p-3`}></img>Saved Maps</li>
                <li  className={`flex items-center ${!active.profile?'opacity-50':'active'}`} onClick={()=>{handleNavChange('profile')}}><img src={userLogo} className={`${active.profile&&'active-svg'} h-12 p-3`}></img>Account</li>
                <li  className={`flex items-center  ${!active.feedback?'opacity-50':'active'}`} onClick={()=>{handleNavChange('feedback')}}><img src={feedbackLogo} className={`${active.feedback&&'active-svg'}  h-12 p-3`}></img>Feedback</li>
                {userType==="influencer"&&<li  className={`flex items-center ${!active.posts?'opacity-50':'active'}`} onClick={()=>{handleNavChange('posts')}}><img src={post} className={`${active.posts&&'active-svg'} h-12 p-3`}></img>Posts</li>}
               
                <li  className={`flex items-center  opacity-50` } onClick={handleChangePasswordOpen}><div className='h-12 p-3'><FaLock size={24} style={{color:'#B1B1B1'}}/></div>Change Password</li>

                <li  className={`flex items-center  opacity-50` } onClick={handleLogOutOpen}><div className='h-12 p-3'><FaRightToBracket size={24} style={{color:'#B1B1B1'}}/></div>Log Out</li>
                                
            </ul>
            <div className='absolute md:hidden p-2 top-2 right-2 opacity-50' onClick={()=>{setIsDrawerOpen(false)}}>
                <FaXmark size={32}/>
            </div>

           
        </div>
    )
}