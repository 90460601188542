
import axiosInstance from "../utils/AxiosInterceptor";

const userService = {
  // Retrieve all cities
  addInfluencer: async (userData) => {
    try {
      const response = await axiosInstance.post('/users/addInfluencer', userData);
      return response.data;
    } catch (error) {
      console.error('Error while adding Influencer:', error);
      throw error;
    }
  }
}
  export default userService