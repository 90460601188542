
import axiosInstance from "../utils/AxiosInterceptor";

const userService = {
  // Retrieve all cities
  updateUser: async (userData) => {
    try {
      const response = await axiosInstance.post('/users/updateUser', userData);
      return response.data;
    } catch (error) {
      console.error('Error Updating User Details:', error);
      throw error;
    }
  },
  getUserData: async () => {
    try {
      const response = await axiosInstance.get('/users/getUserData');
      return response.data;
    } catch (error) {
      console.error('Error Updating User Details:', error);
      throw error;
    }
  },
  intiateProfileVerification: async () => {
    try {
      const response = await axiosInstance.get('/users/intiateProfileVerification');
      return response.data;
    } catch (error) {
      console.error('Error Updating User Details:', error);
      throw error;
    }
  },
  verifyProfile: async (otp) => {
    try {
      const response = await axiosInstance.post('/users/verifyProfile',{otp});
      return response.data;
    } catch (error) {
      console.error('Error Updating User Details:', error);
      throw error;
    }
  },
}
  export default userService