import React from 'react';

const SuccessModal = ({ message, onClose, isOpen }) => {
  if(!isOpen){
    return
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black opacity-50 w-full h-full absolute inset-0"></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-10 max-w-sm mx-auto">
        <h2 className="text-2xl mb-4 text-center">Success</h2>
        <p className="mb-4 text-center">{message}</p>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
