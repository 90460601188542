import React, { useState, useEffect, useRef, useCallback } from 'react';

import postService from '../../services/PostService';
import SeePost from './Seepost';
import ImageOrVideoPreview from '../../Components/Image/ImageOrVideoPreview';

const ShowPost = ({ markerId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [currentPost, setCurrentPost] = useState(null);
    const [postInfo, setPostInfo] = useState({ index: 0, length: 0 });
    const [openSeePost, setOpenSeePost] = useState(false);

    const videoRefs = useRef([]);

    const handleOpenSeePost = (index) => {
        let map = document.getElementById("map");
        if (map) {
            map.style.zIndex = 0;
        }
        setCurrentPost(data[index]);
        setPostInfo({ index, length: data.length });
        setOpenSeePost(true);
    };

    const handleCloseSeePost = () => {
        setOpenSeePost(false);
    };

    const handleOnNext = () => {
        setCurrentPost(data[postInfo.index + 1]);
        setPostInfo({ index: postInfo.index + 1, length: data.length });
    };
    const handleOnPrev = () => {
        setCurrentPost(data[postInfo.index - 1]);
        setPostInfo({ index: postInfo.index - 1, length: data.length });
    };

 
    useEffect(() => {
        setLoading(true);
        postService.getPostByMarkerId(markerId).then((data) => {
            setData(data);
            setLoading(false);
        });
    }, [markerId]);

    const handleIntersection = useCallback((entries) => {
        let mostVisibleEntry = null;
        let maxVisibleArea = 0;

        entries.forEach(entry => {
            const visibleArea = entry.intersectionRect.width * entry.intersectionRect.height;
            if (visibleArea > maxVisibleArea) {
                maxVisibleArea = visibleArea;
                mostVisibleEntry = entry;
            }
        });

        if (mostVisibleEntry) {
            const index = videoRefs.current.findIndex(video => video === mostVisibleEntry.target);
            if (index !== -1) {
                videoRefs.current.forEach((video, i) => {
                    if (i === index) {
                        video.play();
                        video.muted = false;
                    } else {
                        video.pause();
                        video.muted = true;
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.85,
        });
        try {

            videoRefs.current.forEach(video => observer.observe(video));
        } catch { }
        return () => {
            try {
                videoRefs.current.forEach(video => observer.unobserve(video));
            }
            catch { }
        };
    }, [data, handleIntersection]);

    const handleVideoPlay = (index) => {
        videoRefs.current.forEach((video, i) => {
            if (i !== index) {
                video.pause();
                video.muted = true;
            }
            else {
                video.muted = false;
            }
        });
    };

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                video.addEventListener('play', () => handleVideoPlay(index));
            }
        });

        return () => {
            videoRefs.current.forEach((video, index) => {
                if (video) {
                    video.removeEventListener('play', () => handleVideoPlay(index));
                }
            });
        };
    }, [data]);

    return (
        <div className={`flex justify-center items-center ${loading && 'h-64'} bg-gray-100 w-full mb-4`}>
            {loading ? (
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
            ) : (
                <div className='w-full flex flex-wrap bg-white'>
                    {data.map((post, index) => (
                        <div className='w-full md:w-1/3'>
                            <div key={`index`} className={`relative w-full md:aspect-square pointer-cursor ${index % 4 < 3 && index !== data.length - 1 && 'md:border-r border-black'} ${index > 3 && 'md:border-t border-black'} md:hidden`}>
                                <ImageOrVideoPreview ref={el => videoRefs.current[index] = el} src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl} type={post.type} username={post.username} instagramUrl={post.instagramUrl} />
                            </div>
                            <div key={`desk-${index}`} className={`relative w-full md:aspect-square pointer-cursor ${index % 4 < 3 && index !== data.length - 1 && 'md:border-r border-black'} ${index > 3 && 'md:border-t border-black'} hidden md:block`} onClick={() => { handleOpenSeePost(index) }}>
                                <ImageOrVideoPreview src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl} type={post.type} username={post.username} instagramUrl={post.instagramUrl} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <SeePost
                currentPost={currentPost}
                onClose={handleCloseSeePost}
                isOpen={openSeePost}
                postInfo={postInfo}
                onNext={handleOnNext}
                onPrev={handleOnPrev}
             
            />
        </div>
    );
};

export default ShowPost;
