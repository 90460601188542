import React from 'react';

const LoadingButton = ({ loading, text }) => {
  return (
    <button
      type="submit"
      disabled={loading}
      className={`group relative w-full flex justify-center mt-2 md:mt-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${loading ? 'bg-gray-600' : 'bg-[#07B464]'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        {loading ?
          (<div className="animate-spin rounded-full h-4 w-4 border-r-2 border-white"></div>)
          :
          ''
        }
      </span>
      {loading ? text.load : text.main}
    </button>
  );
};

export default LoadingButton;
