// LogoutModal.js
import React from 'react';

const LogoutModal = ({ isOpen, onClose, onLogout, loading }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 p-2">
            <div className="bg-white p-4 rounded shadow-lg w-full md:w-96">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-bold">Confirm Logout</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-3xl">
                        &times;
                    </button>
                </div>
                <div>
                    <p className="text-gray-700 mb-4">Are you sure you want to log out?</p>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onLogout}
                        className={`bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Logging out...' : 'Log Out'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;
