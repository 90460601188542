import React, { useState, useRef, useEffect } from 'react';
import { BsUpload, BsTrash, BsCloudUpload, BsCheckCircle, BsExclamationCircle } from 'react-icons/bs';
import { initiateUpload, uploadChunks, completeUpload } from '../utils/ImageUploader';

const ImageInput = ({ onChange, imageUrl, width, height}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [containerHeight, setContainerHeight] = useState('auto');
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const inputRef = useRef(null);
  const [dimension,setDimension]=useState(null)

  useEffect(()=>{
    if(imageUrl && imageUrl!=''){
      setSelectedImage(imageUrl)
      setUploadStatus('success');
    }
  },[imageUrl])

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    
      
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          setSelectedImage(image.src);
          setFile(file);
          setContainerHeight('auto'); // Reset height to auto when image is selected
          setDimension({width:image.width,height:image.height})
          setUploadStatus(null)
          onChange(null, 0, 0);
        };
      };
      reader.readAsDataURL(file);
      
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setFile(null);
    setDimension(null)
    setContainerHeight('12rem'); // Set a fixed height when no image is selected
    onChange(null, 0, 0); // Reset dimensions when image is removed
    inputRef.current.value = ''; // Reset the input field to allow selecting the same image again
    setUploadStatus(null); // Reset upload status when image is removed
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    setUploadStatus(null); // Reset upload status when new upload starts
    try {
      const { fileId, chunkSize } = await initiateUpload(file);
      await uploadChunks(file, fileId, chunkSize);
      const {downloadUrl}=await completeUpload(fileId);
      
      onChange({fileId,imageUrl:downloadUrl}, dimension.width, dimension.height);
      setUploadStatus('success');
    } catch (error) {
      console.error('Error during upload:', error);
      setUploadStatus('error');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="flex flex-col w-3/4 border border-gray-300 rounded-md p-4">
      <label className="text-sm font-semibold mb-1">Base Map Image:</label>
      <div className="relative flex items-center justify-center bg-gray-100 rounded-md overflow-hidden" style={{ height: selectedImage ? 'auto' : '12rem' }}>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
          required={!imageUrl && imageUrl!=''}
        />
        {selectedImage ? (
          <img src={selectedImage } alt="Selected" className="max-w-full max-h-full" />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <BsUpload className="h-12 w-12 text-gray-400" />
            <span className="ml-2 text-gray-500">Upload an image</span>
          </div>
        )}
        {selectedImage && (
          <button
            type="button"
            onClick={handleRemoveImage}
            className="absolute top-1 right-1 bg-red-500 rounded-full p-1 flex items-center justify-center"
          >
            <BsTrash className="h-6 w-6 text-white" />
          </button>
        )}
      </div>
      {imageUrl && (
        <div className="mt-2">
          <p className="text-sm text-gray-600">Selected Image Dimensions:</p>
          <p className="text-sm">{`Width: ${width}px, Height: ${height}px`}</p>
        </div>
      )}
      {selectedImage && (
        <div className="mt-2 flex items-center">
          {uploadStatus === 'success' ? (
            <div className="flex items-center text-green-500">
              <BsCheckCircle className="mr-2" />
              <span>Upload successful</span>
            </div>
          ) : uploadStatus === 'error' ? (
            <div className="flex items-center text-red-500">
              <BsExclamationCircle className="mr-2" />
              <span>Error during upload. Please try again.</span>
              <button
                type="button"
                onClick={handleUpload}
                className={`ml-4 px-3 py-2 rounded-md text-white flex items-center justify-center bg-red-500 hover:bg-red-600`}
              >
                Retry
              </button>
            </div>
          ) : (
            <button
              type="button"
              onClick={handleUpload}
              className={`px-3 py-2 rounded-md text-white flex items-center justify-center ${uploading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
              disabled={uploading}
            >
              {uploading ? (
                <span className="loader"></span> // A loader can be added here if needed
              ) : (
                <>
                  <BsCloudUpload className="mr-2" />
                  Upload Image
                </>
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageInput;
