import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaXmark } from 'react-icons/fa6';
import ImageOrVideoOrFileInput from '../../components/ImageOrVideoOrFileInput'; // Adjust the path as necessary
import { CityContext } from '../../Context/CityContext';
import LayerService from '../../services/LayerService';
import { createMarker } from '../../services/MarkerService';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from '../../components/Modal/SuccessModal';
import ExistingIcon from '../../components/MarkerIcon/ExistingIcons';

const CreateMarkerForm = () => {
  const { addMarker, setAddMarker, city, setCity, isChecked, setIsChecked,setSelectedMarker} = useContext(CityContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectExistingMarker,setSelectExistingMarker]=useState(false)
  const handleSuccessClose = () => {
    setAddMarker(null);
  };

  const handleOpenSelectExistingMarker=()=>{
    setSelectExistingMarker(true)
  }

  const handleCloseSelectExistingMarker=()=>{
    setSelectExistingMarker(false)
  }

  const [imageError, setImageError] = useState(addMarker.error ? addMarker.error : {
    marker_icon: { error: false, selected: false, required: true, uploaded: false },
    thumbnail: { error: false, selected: false, required: false, uploaded: false }
  });

  const initialFormData = {
    name: '',
    direction_url: '',
    marker_icon: '',
    coordinates: {
      longitude: '',
      latitude: '',
    },
    caption: '',
    thumbnail: {
      fileId: '',
      imageUrl: '',
    }
  };

  const [formData, setFormData] = useState(addMarker.form ? addMarker.form : initialFormData);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = city.baseMap.imageUrl;

    image.onload = () => {
      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Calculate the scaled dimensions to fit the canvas
      let canvasWidth = canvas.width;
      let canvasHeight = canvasWidth / aspectRatio;

      // Adjust canvas height if it exceeds the canvas width
      if (canvasHeight > canvas.height) {
        canvasHeight = canvas.height;
        canvasWidth = canvasHeight * aspectRatio;
      }

      // Clear canvas and draw the image scaled to fit
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

      // Example position at 1/3, 0.6 relative to the scaled image dimensions
      drawPoint(context, canvasWidth * (addMarker ? addMarker.lng : 0), canvasHeight - canvasHeight * (addMarker ? addMarker.lat : 0));
    };
  }, [addMarker]);

  const drawPoint = (context, x, y) => {
    const pointSize = 5;

    // Set shadow properties
    context.shadowColor = 'red';  // Shadow color
    context.shadowBlur = 10;      // Blur amount
    context.shadowOffsetX = 3;    // Horizontal shadow offset

    // Draw the main point
    context.fillStyle = "#FF0000";
    context.beginPath();
    context.arc(x, y, pointSize, 0, Math.PI * 2, true);
    context.fill();

    // Reset shadow properties (if needed for subsequent drawings)
    context.shadowColor = 'transparent';
    context.shadowBlur = 0;
    context.shadowOffsetX = 0;
  };

  const chooseCoordinateAgain = () => {
    let data = { ...addMarker };
    data.selected = false;
    data.form = formData;
    data.error = imageError;
    setAddMarker(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCoordinatesChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      coordinates: {
        ...formData.coordinates,
        [name]: value,
      },
    });
  };

  const handleThumbnailChange = (imageData, width, height) => {
    let data = { ...imageError };
    if (!imageData) {
      data.thumbnail.selected = false;
      data.thumbnail.uploaded = false;
    }
    else if (imageData.selected) {
      data.thumbnail.selected = true;
    }
    else {
      data.thumbnail.uploaded = true;
    }

    setImageError(data);
    setFormData({
      ...formData,
      thumbnail: imageData,
    });
  };

  const handleMarkerIconChange = (imageData, width, height) => {
    //console.log(imageData)
    let data = { ...imageError };
    if (!imageData) {
      data.marker_icon.selected = false;
      data.marker_icon.uploaded = false;
    }
    else if (imageData.selected) {
      data.marker_icon.selected = true;
    }
    else {
      data.marker_icon.uploaded = true;
    }

    setImageError(data);
    
    setFormData({
      ...formData,
      marker_icon: imageData,
    });
  };

  const handleSubmit = async (e) => {
    
    setLoading(true);
    e.preventDefault();
    let data = { ...imageError };
    let found = false;
    if (!data.marker_icon.uploaded) {
      data.marker_icon.error = true;
      found = true;
    }

    if (data.thumbnail.selected && !data.thumbnail.uploaded) {
      data.thumbnail.error = true;
      found = true;
    }
    if (found) {
      toast.error("Please upload all Required Images");
      setImageError(data);
      setLoading(false);
      return;
    }
    try {
      ////console.log(formData);
      let data = { ...formData };
      data.coordinates.latitude = addMarker.lat;
      data.coordinates.longitude = addMarker.lng;
      const response = await createMarker(addMarker.cityId, addMarker.layerId, data);

      const updatedCity = { ...city };
      updatedCity.layers.forEach((layer) => {
        if (layer._id === addMarker.layerId) {
          layer.markers.push(response); // Assuming response.data is the marker object returned by createMarker
        }
      });
      response.object = "marker";
      response.action = "add";
      response.layerId = addMarker.layerId;
      updatedCity.changedData = response;
      let updatedChecked = { ...isChecked };
      updatedChecked[response._id] = true;
      setIsChecked(updatedChecked);
      setCity(updatedCity);

      setSuccess(true);
      setSelectedMarker({...response,cityId:addMarker.cityId,layerId:addMarker.layerId})
      setLoading(false);
      
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Some error Occurred, Please Try Again");
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setAddMarker(null);
  };

  let isMarker={
    cityId:city._id
  }

  return (
    <div>
    <form onSubmit={handleSubmit} className={`w-4/5 p-4 shadow-lg rounded-md bg-white ${loading && 'pointer-events-none opacity-50'}`}>
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">Create Marker</h2>

      <div className="flex flex-row mb-4 w-full">
        <div className="w-3/5">
          <canvas ref={canvasRef} width="350" height="auto" />
        </div>
        <div className="w-2/5 pl-4">
          <label className="block text-sm font-medium text-gray-700">
            Coordinates
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="longitude"
            value={addMarker.currLng.toFixed(2)}
            onChange={handleCoordinatesChange}
            placeholder="Longitude"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            disabled
            required
          />
          <input
            type="text"
            name="latitude"
            value={addMarker.currLat.toFixed(2)}
            onChange={handleCoordinatesChange}
            placeholder="Latitude"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            required
            disabled
          />
          <button
            type="button"
            className="mt-4 px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            onClick={chooseCoordinateAgain}
          >
            Change Coordinates
          </button>
        </div>
      </div>

      <div className="flex flex-row mb-4">
        <div className="w-1/4 mr-4">
          <label className="block text-sm font-medium text-gray-700">
            Thumbnail Image
          </label>
          <ImageOrVideoOrFileInput onChange={handleThumbnailChange} imageUrl={formData.thumbnail && formData.thumbnail.large && formData.thumbnail.large.imageUrl} error={imageError.thumbnail.error} uploaded={imageError.thumbnail.uploaded} />
        </div>
        <div className="w-1/4 mr-4">
          <label className="block text-sm font-medium text-gray-700">
            Marker Image
            <span className="text-red-500">*</span>
          </label>
          <ImageOrVideoOrFileInput onChange={handleMarkerIconChange} imageUrl={formData.marker_icon && formData.marker_icon.large && formData.marker_icon.large.imageUrl} error={imageError.marker_icon.error} uploaded={imageError.marker_icon.uploaded} isMarker={isMarker}/>
          <button
            type="button"
            className="w-full mt-2 px-2 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 text-center"
            onClick={handleOpenSelectExistingMarker}
          >
            Existing Icons
          </button>
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700">
            Name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            required
          />
 <label className="block text-sm font-medium text-gray-700">
            Direction URL
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="direction_url"
            value={formData.direction_url}
            onChange={handleChange}
            placeholder="Direction URL"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            required
          />
        
        </div>
      </div>

      <div className="flex flex-row mb-4">
        <div className="w-full">
         
            <label className="block text-sm font-medium text-gray-700">
            Caption
            
          </label>
          <textarea
            name="caption"
            value={formData.caption}
            onChange={handleChange}
            placeholder="Caption"
            className="mt-1 p-2 block w-full border rounded-md shadow-sm"
            rows="3"
           
          ></textarea>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          className="px-4 py-2 mr-2 border rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          Save
        </button>
      </div>

      {success &&<SuccessModal message={`Marker ${formData.name} Created Successfully`} onClose={handleSuccessClose}/>}
      {loading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
            
    </form>
    {selectExistingMarker && <ExistingIcon cityId={city._id} handleCloseSelectExistingMarker={handleCloseSelectExistingMarker} setIconUrl={handleMarkerIconChange}/>}
    </div>
  );
};

export default CreateMarkerForm;
