import { useContext, useEffect, useState } from "react"
import { CityContext } from "../../Context/CityContext"
import PageNotFound from "./PageNotFound"
import LeftBar from "./LeftBar"
import RightBar from "./RightBar"

const City = () => {
    const { city, loading } = useContext(CityContext)
    const [isLayerOpen, setIsLayerOpen] = useState(false)
    const [checkData, setCheckData] = useState(null)

    useEffect(()=>{
        let tempCheckData = {}
        if (city?.mapData?.checkData) {
            tempCheckData = { ...city.mapData.checkData }
        }
        else if (city) {
            for (let layer of city.layers) {
                tempCheckData[layer._id] = true;
                for (let marker of layer.markers) {
                    tempCheckData[marker._id] = true;
                }
            }
        }

        setCheckData(tempCheckData)
       },[city])

    if (loading) {
        return null
    }

    if (!city) {
        return <PageNotFound />
    }

   
    return (<div className="flex flex-col md:flex-row w-full h-screen">

        <div className={`w-full md:w-1/3.5  bg-gray-200 md:block ${isLayerOpen && 'fixed left-0 right-0 top-0 left-0 inset-0 bg-gray-400 bg-opacity-50 z-10'}`} id="left-panel">

            <LeftBar isLayerOpen={isLayerOpen} setIsLayerOpen={setIsLayerOpen} checkData={checkData} setCheckData={setCheckData}/>
        </div>

        <div className={`w-full md:w-2.5/3.5 bg-gray-100" id="main-content md:block overflow-y-auto`}>
            <RightBar checkData={checkData}/>
        </div>
    </div>)
}

export default City