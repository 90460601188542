import React, { useState } from 'react';
import { forgotPassword, resetPassword } from '../../../services/AuthService';
import { useLoaderData } from 'react-router-dom';
import EmailInput from './EmailInput';
import EmailNotFound from './EmailNotFound';
import OtpVerification from './OtpVerification';
import SuccessModal from '../../../components/Modal/SuccessModal';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpVerificationError, setOtpVerificationError] = useState('');
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [found, setFound] = useState(true);
  const [password,setPassword]= useState('')
  const [success, setSuccess]= useState(false)
  let { authenticated } = useLoaderData();

  if (authenticated) {
    window.location.href = "/";
  }

  const handleSuccessClose=()=>{
    window.location.href = "/";
  }

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      await forgotPassword(email);
      setOtpSent(true);
      setFound(true);
    } catch (error) {
      if (error.response?.status === 404) {
        setOtpSent(true);
        setFound(false);
      } else {
        setError(error.response?.data?.error || 'An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerify = async (event) => {
    console.log("Here")
    event.preventDefault();
    setOtpVerificationLoading(true);
    setOtpVerificationError('');
    try {
      if(otp.length!==6){
        setOtpVerificationError("Error! OTP must be 6 numbers long.")
        return
      }
      if(password.length<5){
        setOtpVerificationError("Error! Password must be atleast 5 numbers long.")
        return
      }
      await resetPassword({email,otp,password})
      setSuccess(true)
      
    } catch (error) {
      
      if (error.response?.status === 401) {
        setOtpVerificationError('Invalid OTP');
        return
      }
     
      setOtpVerificationError(error.response?.data?.error || 'An unexpected error occurred');

    } finally {
      setOtpVerificationLoading(false);
    }
  };

  return (
    <div className="fixed left-0 right-0 top-0 left-0 inset-0 h-full flex items-center justify-center bg-cover bg-center">
      {!otpSent && <EmailInput email={email} setEmail={setEmail} handleSubmit={handleForgotPassword} loading={loading} error={error} />}
      {otpSent && !found && <EmailNotFound setOtpSent={setOtpSent} setFound={setFound} />}
      {otpSent && found && <OtpVerification password={password} setPassword={setPassword} email={email} setOtpSent={setOtpSent} setFound={setFound} otp={otp} setOtp={setOtp} handleSubmit={handleOtpVerify} loading={otpVerificationLoading} error={otpVerificationError} />}
    {success && <SuccessModal  message={"Password is Reset Successfully"} onClose={handleSuccessClose}/>}
    </div>
  );
};

export default ForgotPassword;
