import React, { useState } from 'react';
import { signup } from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import riitiiText from './riitii_text.svg';
import riitiiLogo from './riitii_logo.svg';
import { FaAt, FaEyeSlash, FaLock } from 'react-icons/fa6';
import { useLoaderData } from "react-router-dom";
import Login from './Login';


const SignUp = () => {
  const navigate=useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword,setShowPassword]=useState(false)

    let { authenticated }=useLoaderData()
    if(authenticated)
    return  window.location.href="/"

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      const result = await signup(email, password);
      // Handle successful login (e.g., redirect to dashboard)
      navigate('/addinfo')
      
    } catch (error) {
      setError(error.response?.data?.error || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed left-0 right-0 top-0 left-0 inset-0 h-full flex items-center justify-center bg-cover bg-center">
      <div className="max-w-md w-full bg-white p-4 md:p-8">
        <div className='flex justify-center m-2 md:m-4'>
          <img src={riitiiText}/>
        </div>
       
        <div className='rounded-lg p-4' style={{boxShadow:'0 0 10px 3px #b2b3b8'}}>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 md:px-4 md:py-3 rounded relative" role="alert">
            <strong className="font-bold">Error! <span > {error}</span></strong>
            
          </div>
        )}
        <div className='flex justify-between items-center pt-2 pb-2 mb-2'>
          <p className='text-2xl font-bold ml-2'>Sign Up</p>
          <img src={riitiiLogo}/>
        </div>
        <form className="" onSubmit={handleSignUp}>
          <div className="rounded-md shadow-sm space-y-4">
            <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:z-10'>
              <FaAt size={17} className='mr-2 opacity-50' style={{color:'#0B4654'}} />
              <label htmlFor="email" className="sr-only">Username</label>
              <input
                id="email"
                name="email"
                type="text"
                required
                className="flex-1 appearance-none focus:outline-none  sm:text-sm"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='flex px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 items-center rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:z-10'>
            <FaLock size={17} className='mr-2 opacity-50' style={{color:'#0B4654'}}/>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type={showPassword?'text':'password'}
                required
                className="flex-1 appearance-none focus:outline-none  sm:text-sm"
                placeholder="Create Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FaEyeSlash size={17} className={`mr-2 ${showPassword?'opacity-100':'opacity-50'}`} style={{color:'#0B4654'}} onClick={()=>{setShowPassword(!showPassword)}}/>
                
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`group relative w-full flex justify-center mt-2 md:mt-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${loading ? 'bg-gray-600' : 'bg-[#07B464]'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {loading ?
                (<div className="animate-spin rounded-full h-4 w-4 border-r-2 border-white"></div>)
                 :
                  //(<FaLock size={17}/>)
                  ''}
              </span>
              {loading ? 'Signing up...' : 'Sign up'}
            </button>
          </div>
        </form>

        <div className='flex justify-center items-center text-black mt-2'>
         <span className='opacity-50 mr-2'>Already have an account? </span><span className='text-[#07B464] text-lg cursor-pointer' onClick={()=>{window.location.href="/"}}>Sign In</span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
