import axiosInstance from "../utils/AxiosInterceptor";

const cityService = {
  // Retrieve all cities
  getAllCities: async () => {
    try {
      const response = await axiosInstance.get('/city');
      return response.data;
    } catch (error) {
      console.error('Error retrieving cities:', error);
      throw error;
    }
  },

  // Create a new city
  createCity: async (cityData) => {
    try {
      const response = await axiosInstance.post('/city', cityData);
      return response.data;
    } catch (error) {
      console.error('Error creating city:', error);
      throw error;
    }
  },

  // Retrieve a city by ID
  getCityById: async (id) => {
    try {
      const response = await axiosInstance.get(`/city/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error retrieving city with ID ${id}:`, error);
      throw error;
    }
  },

  // Update a city by ID
  updateCity: async (id, cityData) => {
    try {
      const response = await axiosInstance.put(`/city/${id}`, cityData);
      return response.data;
    } catch (error) {
      console.error(`Error updating city with ID ${id}:`, error);
      throw error;
    }
  },

  // Delete a city by ID (soft delete)
  deleteCity: async (id) => {
    try {
      await axiosInstance.delete(`/city/${id}`);
    } catch (error) {
      console.error(`Error deleting city with ID ${id}:`, error);
      throw error;
    }
  },
};

export default cityService;
